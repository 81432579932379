import get from 'lodash/get'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { objectToCookiesString } from '../utils/cookies'

let cookiesInterceptor: {
    (config: AxiosRequestConfig): AxiosRequestConfig
    (value: AxiosRequestConfig): AxiosRequestConfig | Promise<AxiosRequestConfig>
}
export const cookiesRequest = (cookies: { [key: string]: string }): void => {
    if (cookies) {
        cookiesInterceptor = (config: AxiosRequestConfig) => {
            config.headers.cookie = objectToCookiesString(cookies)
            return config
        }
    }
}
type errorMessage = {
    status?: string | undefined
    message?: string | undefined
}

export const currentErrorInfo: errorMessage = {
    status: undefined,
    message: undefined
}
// @ts-ignore
const isBrowser = typeof window !== 'undefined'

const BACKEND_URL =
    // @ts-ignore
    isBrowser && process.env.NODE_ENV !== 'test' ? window.env.RAZZLE_BACKEND_URL : process.env.RAZZLE_BACKEND_URL
// @ts-ignore
const REMOTE_DEV_AUTH =
    // @ts-ignore
    isBrowser && process.env.NODE_ENV !== 'test' ? window.env.REMOTE_DEV_AUTH : process.env.REMOTE_DEV_AUTH

export const getLangFromUrl = (): string => {
    const url = isBrowser ? window.location.pathname : process.env.URL
    const firstItemInUrl = url.split('/')[1]
    if (firstItemInUrl === 'ru') return '/ru'
    if (firstItemInUrl === 'en') return '/en'
    return ''
}

export const axiosConfig = (): AxiosRequestConfig => {
    return {
        baseURL: `${BACKEND_URL + getLangFromUrl()}/b`,
        withCredentials: true
    }
}

const getAxios = (): AxiosInstance => {
    if (REMOTE_DEV_AUTH) {
        axiosConfig().headers = {
            ...axiosConfig().headers,
            Authorization: REMOTE_DEV_AUTH
        }
    }

    const instance = axios.create(axiosConfig())

    instance.interceptors.request.use(requestInterceptor)
    instance.interceptors.request.use(cookiesInterceptor)
    instance.interceptors.response.use(responseInterceptor)

    return instance
}

const requestInterceptor = (config: AxiosRequestConfig) => {
    return config
}

const responseInterceptor = (response: AxiosResponse) => {
    if (get(response, 'data.status') === 'error') {
        currentErrorInfo.status = response.data.status
        currentErrorInfo.message = response.data.messages
        return Promise.reject(response.data)
    }

    return response.data
}

export const getWrapper = (method: string, params: unknown = null, post = false): Promise<unknown> => {
    const axios = getAxios()
    let response = null
    //@ts-ignore
    response = params ? (post ? axios.post(method, params) : axios.get(method, params)) : axios.get(method)
    response.catch((error) => {
        // console.log('%c--------- Error beginning ----------', 'background: blue')
        // console.log('Method returned error: ' + method)
        // //@ts-ignore
        // if (params) console.log('with params: ' + JSON.stringify(params.params))
        // if (error.response) {
        //     console.log(error.response.status)
        //     console.log(error.response.headers)
        // } else if (error.request) {
        //     console.log(error.request)
        // } else {
        //     console.log('Error', error.message)
        // }
        // console.log('%c---------- Error ending ----------', 'background: blue')
    })
    return response
}

export default getAxios
