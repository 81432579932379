import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from '@reduxjs/toolkit'
import {
    getContributions,
    getContribution,
    getPendingContributionMaterials,
    submitContribution,
    deletePendingMaterial,
    getIndexContributions,
    addFavoriteContribution,
    removeFavoritesContribution,
    getNextContribution,
    getPrevContribution,
    getContributionStats
} from '../../api/contributions'

import { pendingContributionsReducer, rejectedContributionsReducer } from '../commonReducers'

import {
    ContributionsState,
    FetchContribution,
    FetchContributionsPayload,
    FetchContributionPayload,
    FetchPendingContributionPayload,
    SubmitContribution,
    DeletePendingMaterial,
    ContributionMaterialType,
    FetchIndexContributions,
    ContributionFavoriteId,
    FetchNextPrevContribution,
    ContributionType
} from './contributionsTypings'
import findIndex from 'lodash/findIndex'
import isNull from 'lodash/isNull'
import cacheSlice from '../cacheSlise/cacheSlice'
import { CachedNextPrevContribution, CacheState } from '../cacheSlise/cacheTypings'

const initialState: ContributionsState = {
    list: [],
    swiperList: [],
    timestamp: null,
    prevSlideLoading: false,
    nextSlideLoading: false,
    swiperListStopLoadPrev: false,
    swiperListStopLoadNext: false,
    cachedPrevList: null,
    error: null,
    loading: false,
    contribution: null,
    pendingMaterials: [],
    openedFromProfile: 'index',
    total_count: undefined,
    filters: null,
    stats: null
}

export const fetchIndexContributions = createAsyncThunk(
    'fetchIndexContributions',
    async (params: FetchIndexContributions) => {
        return getIndexContributions(params)
    }
)

export const fetchContributions = createAsyncThunk('fetchContributions', async () => {
    return getContributions()
})

export const fetchContribution = createAsyncThunk(
    'fetchContribution',
    async (params: FetchContribution, { getState }) => {
        // @ts-ignore
        const { cache } = getState()
        if (cache.cachedContributions.length) {
            const index = cache.cachedContributions.findIndex((item: ContributionType) => item.number === params.number)
            if (index !== -1)
                return new Promise((resolve) => {
                    // @ts-ignore
                    resolve({ contribution: cache.cachedContributions[index] })
                })
        }

        return getContribution(params)
    }
)

export const fetchPrevSlide = createAsyncThunk(
    'fetchPrevSlide',
    async (params: FetchNextPrevContribution, { getState }) => {
        // @ts-ignore
        const { cache } = getState()
        if (cache.cachedPrevContributions.length) {
            const index = cache.cachedPrevContributions.findIndex(
                (item: CachedNextPrevContribution) => item.meta.id === params.id && item.meta.context === params.context
            )
            if (index !== -1)
                return new Promise((resolve) => {
                    // @ts-ignore
                    resolve({ contribution: cache.cachedPrevContributions[index].contribution })
                })
        }
        return getPrevContribution(params)
    }
)

export const fetchCachedSlide = createAsyncThunk('fetchCachedSlide', async (params: FetchContribution) => {
    return getContribution(params)
})

export const fetchNextSlide = createAsyncThunk(
    'fetchNextSlide',
    async (params: FetchNextPrevContribution, { getState }) => {
        // @ts-ignore
        const { cache } = getState()
        if (cache.cachedNextContributions.length) {
            const index = cache.cachedNextContributions.findIndex(
                (item: CachedNextPrevContribution) => item.meta.id === params.id && item.meta.context === params.context
            )
            if (index !== -1)
                return new Promise((resolve) => {
                    // @ts-ignore
                    resolve({ contribution: cache.cachedNextContributions[index].contribution })
                })
        }
        return getNextContribution(params)
    }
)

export const fetchPendingContributions = createAsyncThunk('fetchPendingContributions', async () => {
    return getPendingContributionMaterials()
})

export const contributionSubmit = createAsyncThunk('contributionSubmit', async (params: SubmitContribution) => {
    return submitContribution(params)
})

export const pendingMaterialDelete = createAsyncThunk(
    'pendingMaterialDelete',
    async (params: DeletePendingMaterial) => {
        return deletePendingMaterial(params)
    }
)
export const addFavoriteContributionThunk = createAsyncThunk(
    'addFavoriteContributionThunk',
    async (params: ContributionFavoriteId) => {
        return addFavoriteContribution(params)
    }
)

export const removeFavoriteContributionThunk = createAsyncThunk(
    'removeFavoriteContributionThunk',
    async (params: ContributionFavoriteId) => {
        return removeFavoritesContribution(params)
    }
)

export const fetchContributionsStat = createAsyncThunk('fetchContributionsStat', async () => {
    return getContributionStats()
})

const contributionsSlice = createSlice({
    name: 'contributions',
    initialState,
    reducers: {
        clearContribution(state) {
            state.contribution = null
        },
        addPendingMaterial(state, action: PayloadAction<ContributionMaterialType[]>) {
            state.pendingMaterials = [...state.pendingMaterials, ...action.payload]
        },
        deleteErrorPendingMaterial(state, action: PayloadAction<{ id: number }>) {
            state.pendingMaterials = state.pendingMaterials.filter(
                (material: ContributionMaterialType) => material.id !== action.payload.id
            )
        },
        switchOpenedFromProfile(state, action: PayloadAction<string>) {
            state.openedFromProfile = action.payload
        },
        clearContributionsList(state) {
            state.list = []
        },
        clearSwiperList(state) {
            state.swiperList = []
        },
        copyContributionToSwiperList(state) {
            state.swiperList = [{ ...state.contribution }]
            state.swiperListStopLoadPrev = false
            state.swiperListStopLoadNext = false
        },
        pushContributionToSwiperList(state) {
            state.swiperList.push(state.contribution)
        },
        unshiftContributionToSwiperList(state) {
            state.swiperList.unshift(state.contribution)
        },
        copyFirstSlideToBeginning(state) {
            state.swiperList.unshift({ ...state.swiperList[0], id: Number(new Date()) })
        },
        setIndexFilters(state, action) {
            state.filters = action.payload
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(fetchCachedSlide.fulfilled, (state, action: AnyAction) => {
                state.loading = false
                state.cachedPrevList = action.payload
            })
            .addCase(fetchPrevSlide.pending, (state) => {
                state.prevSlideLoading = true
            })
            .addCase(fetchPrevSlide.rejected, (state) => {
                state.prevSlideLoading = false
            })
            .addCase(fetchNextSlide.pending, (state) => {
                state.nextSlideLoading = true
            })
            .addCase(fetchNextSlide.rejected, (state) => {
                state.nextSlideLoading = false
            })
            .addCase(fetchPrevSlide.fulfilled, (state, action: AnyAction) => {
                state.prevSlideLoading = false
                state.loading = false
                if (findIndex(state.swiperList, action.payload.contribution) === -1) {
                    state.swiperList[0] = action.payload.contribution
                } else {
                    if (isNull(action.payload.contribution)) {
                        state.swiperListStopLoadPrev = true
                        state.swiperList.shift()
                    }
                }
            })
            .addCase(fetchNextSlide.fulfilled, (state, action: AnyAction) => {
                state.nextSlideLoading = false
                state.loading = false
                if (findIndex(state.swiperList, action.payload.contribution) === -1) {
                    state.swiperList.push(action.payload.contribution)
                } else {
                    if (isNull(action.payload.contribution)) {
                        state.swiperListStopLoadNext = true
                    }
                }
            })

            .addCase(fetchContribution.pending, pendingContributionsReducer)
            .addCase(fetchContributions.pending, pendingContributionsReducer)
            .addCase(fetchIndexContributions.pending, pendingContributionsReducer)
            .addCase(fetchContributions.fulfilled, (state, action: PayloadAction<FetchContributionsPayload>) => {
                state.loading = false
                state.timestamp = Number(new Date())
                state.list = [...action.payload.list]
            })

            .addCase(fetchContribution.fulfilled, (state, action: PayloadAction<FetchContributionPayload>) => {
                state.loading = false
                state.contribution = action.payload.contribution
            })

            .addCase(fetchIndexContributions.fulfilled, (state, action: PayloadAction<FetchContributionsPayload>) => {
                state.loading = false
                state.total_count = action.payload.total_count
                action.payload.list.forEach((item) => {
                    state.timestamp = Number(new Date())
                    if (state.list.findIndex((listItem) => listItem.id === item.id) === -1)
                        state.list = state.list.concat([item])
                })

                // if (state.list.findIndex((item) => item.id === action.payload.list[0].id) === -1)
                //     state.list = state.list.concat(action.payload.list)
            })

            .addCase(
                fetchPendingContributions.fulfilled,
                (state, action: PayloadAction<FetchPendingContributionPayload>) => {
                    state.pendingMaterials = action.payload.materials
                }
            )

            .addCase(pendingMaterialDelete.fulfilled, (state, action) => {
                state.pendingMaterials = state.pendingMaterials.filter(
                    (material: ContributionMaterialType) => material.id !== action.meta.arg.id
                )
            })
            .addCase(fetchIndexContributions.rejected, rejectedContributionsReducer)
            .addCase(fetchContribution.rejected, rejectedContributionsReducer)
            .addCase(fetchContributions.rejected, rejectedContributionsReducer)
            .addCase(addFavoriteContributionThunk.fulfilled, (state) => {
                state.contribution.is_favourite_for_user = 1
            })
            .addCase(removeFavoriteContributionThunk.fulfilled, (state) => {
                state.contribution.is_favourite_for_user = 0
            })
            .addCase(fetchContributionsStat.fulfilled, (state, action) => {
                state.stats = {
                    contribution_counter: action.payload.contribution_counter,
                    material_counter: action.payload.material_counter
                }
            })
            .addCase(fetchContributionsStat.rejected, (state) => {
                state.stats = {
                    contribution_counter: '78 850',
                    material_counter: '427 820'
                }
            })
    }
})
export const {
    clearContribution,
    addPendingMaterial,
    deleteErrorPendingMaterial,
    switchOpenedFromProfile,
    clearContributionsList,
    copyContributionToSwiperList,
    pushContributionToSwiperList,
    unshiftContributionToSwiperList,
    copyFirstSlideToBeginning,
    setIndexFilters,
    clearSwiperList
} = contributionsSlice.actions
export default contributionsSlice.reducer
