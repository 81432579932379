import getAxios, { getLangFromUrl, getWrapper } from './axios'
import { formDataToRequestParams, uploadContributionFormDataToRequestParams } from './helpers'

import {
    AddMaterialsTags,
    AddMaterialsTagsPayload,
    ContributionFavoriteId,
    ContributionMaterialType,
    DeletePendingMaterial,
    FetchContribution,
    FetchContributionPayload,
    FetchContributionsPayload,
    FetchIndexContributions,
    FetchMaterialsTagsPayload,
    FetchNextPrevContribution,
    FetchPendingContributionPayload,
    GetMaterialTags,
    SubmitContribution,
    SubmitContributionPayload,
    UploadContributionMaterial
} from '../store/contributions/contributionsTypings'
import { GetStatsResponse, SingleMaterialResponse } from '../store/tags/tagsTyping'

export const getMaterialTags = (params: GetMaterialTags): Promise<FetchMaterialsTagsPayload> => {
    return getWrapper('/contribution/material/tags', { params }) as Promise<FetchMaterialsTagsPayload>
}

export const addMaterialTags = (params: AddMaterialsTags): Promise<AddMaterialsTagsPayload> => {
    return getWrapper(
        '/tags/add/',
        formDataToRequestParams<AddMaterialsTags>(params),
        true
    ) as Promise<AddMaterialsTagsPayload>
}

export const getContributions = (): Promise<FetchContributionsPayload> => {
    return getWrapper('/list/contribution/index') as Promise<FetchContributionsPayload>
}

export const getIndexContributions = (params: FetchIndexContributions): Promise<FetchContributionsPayload> => {
    return getWrapper('/list/contribution/index', { params }) as Promise<FetchContributionsPayload>
}

export const getContribution = (params: FetchContribution): Promise<FetchContributionPayload> => {
    return getWrapper('/contribution', { params }) as Promise<FetchContributionPayload>
}

export const getNextContribution = (params: FetchNextPrevContribution): Promise<FetchContributionPayload> => {
    return getWrapper('/contribution/next', { params }) as Promise<FetchContributionPayload>
}

export const getPrevContribution = (params: FetchNextPrevContribution): Promise<FetchContributionPayload> => {
    return getWrapper('/contribution/previous', { params }) as Promise<FetchContributionPayload>
}

export const getPendingContributionMaterials = (): Promise<FetchPendingContributionPayload> => {
    return getWrapper('/member/upload/contribution/list/materials') as Promise<FetchPendingContributionPayload>
}

export const uploadMaterial = async (
    params: UploadContributionMaterial,
    onUploadProgress: (id: string, progressEvent: ProgressEvent) => void
): Promise<{ files: ContributionMaterialType[] }> => {
    const axios = getAxios()
    return await axios.request({
        method: 'post',
        onUploadProgress: (progressEvent) => {
            onUploadProgress(params.id, progressEvent)
        },
        url: '/upload/handler.php',
        baseURL: `${process.env.RAZZLE_UPLOAD_URL + getLangFromUrl()}/b`,
        cancelToken: params.source.token,
        data: formDataToRequestParams<UploadContributionMaterial>(params, true)
    })
}

export const deletePendingMaterial = async (params: DeletePendingMaterial): Promise<boolean> => {
    const axios = getAxios()
    return axios.request({
        params,
        method: 'delete',
        url: '/upload/handler.php',
        baseURL: `${process.env.RAZZLE_UPLOAD_URL + getLangFromUrl()}/b`
    })
}

export const submitContribution = (params: SubmitContribution): Promise<SubmitContributionPayload> => {
    return getWrapper(
        '/member/upload/contribution/',
        uploadContributionFormDataToRequestParams<SubmitContribution>(params),
        true
    ) as Promise<SubmitContributionPayload>
}

export const addFavoriteContribution = (params: ContributionFavoriteId): Promise<ContributionFavoriteId> => {
    return getWrapper(
        '/member/favourites/add/contribution/',
        formDataToRequestParams<ContributionFavoriteId>(params),
        true
    ) as Promise<ContributionFavoriteId>
}

export const removeFavoritesContribution = (params: ContributionFavoriteId): Promise<ContributionFavoriteId> => {
    return getWrapper(
        '/member/favourites/remove/contribution/',
        formDataToRequestParams<ContributionFavoriteId>(params),
        true
    ) as Promise<ContributionFavoriteId>
}

export const getContributionMaterial = (params: { id: number }): Promise<SingleMaterialResponse> => {
    return getWrapper('contribution/material', { params }) as Promise<SingleMaterialResponse>
}

export const getContributionStats = (): Promise<GetStatsResponse> => {
    return getWrapper('contribution/stats') as Promise<GetStatsResponse>
}
