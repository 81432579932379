import * as React from 'react'
import { useTranslation } from 'react-i18next'

import './buttonStyles.sass'

interface Props {
    label: string
    small?: boolean
    id?: string
    secondary?: boolean
    mobile?: boolean
    tertiary?: boolean
    onClick?: () => void
    type?: 'submit' | 'button'
    disabled?: boolean
    filters?: boolean
    className?: string
}

const Button: React.FunctionComponent<Props> = ({
    label,
    type,
    id,
    disabled,
    onClick,
    secondary,
    small,
    tertiary,
    mobile,
    className,
    filters,
    ...otherProps
}: Props) => {
    let classname = 'button'
    if (small) classname = classname + ' button_small'
    if (mobile) classname = classname + ' button_mobile'
    if (tertiary) classname = classname + ' button_tertiary'
    if (secondary) classname = classname + ' button_secondary'
    if (filters) classname = classname + ' button_filters'

    const [t] = useTranslation()

    return (
        <button
            id={id}
            type={type}
            onClick={onClick}
            className={className || classname}
            disabled={disabled}
            {...otherProps}
        >
            {t(label)}
        </button>
    )
}

export default Button
