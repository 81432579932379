import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchGeoOptions,
    setDefaultFilters,
    setHideFilters,
    setVisitedGeo,
    updateMyPositionAction,
    updateUserPosition
} from '../store/geo/geoSlice'
import { switchLocationTracking } from '../store/common/commonSlice'
import { RootState } from '../store/rootReducer'
import { GeoState } from '../store/geo/geoTypings'
import { useLocation } from 'react-router-dom'

const usePosition = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const [position, setPosition] = useState({})
    const [error, setError] = useState(null)

    const loggedIn = useSelector((state: RootState) => state.member.loggedIn)
    const { filters, visitedGeo } = useSelector<RootState, GeoState>((state) => state.geo)
    const [update, setUpdate] = useState(null)
    const [shareLocation, setShareLocation] = useState(1)
    const updateTimeout = useRef(null)
    const firstLoading = useRef(true)
    // @ts-ignore
    const getPosition = ({ coords }) => {
        dispatch(updateUserPosition({ lat: Number(coords.latitude), lon: Number(coords.longitude) }))
        if (filters && filters.shareMyLocation)
            dispatch(updateMyPositionAction({ lat: Number(coords.latitude), lon: Number(coords.longitude) }))
        if (firstLoading.current) {
            firstLoading.current = false
            dispatch(switchLocationTracking())
        }
        setPosition({
            lat: Number(coords.latitude),
            lon: Number(coords.longitude)
        })
        dispatch(setHideFilters(false))
    }
    // @ts-ignore
    const onError = (error) => {
        // @ts-ignore
        dispatch(updateUserPosition({ lat: 48.85, lon: 2.35 }))
        dispatch(setDefaultFilters())
        dispatch(setHideFilters(true))
        // firstLoaded.current = true
        setError(error.message)
    }

    useEffect(() => {
        if (location.pathname.includes('/geo')) dispatch(setVisitedGeo())
    }, [location.pathname])

    useEffect(() => {
        setShareLocation(filters ? filters.shareMyLocation : 0)
    }, [filters])

    useEffect(() => {
        if (loggedIn) dispatch(fetchGeoOptions())
    }, [loggedIn])

    useEffect(() => {
        const geolocationVisitedInThisBrowser = localStorage.getItem('geolocationVisitedInThisBrowser')
        const geo = visitedGeo ? navigator.geolocation : null
        if (!geo && visitedGeo) {
            setError('Geolocation is not supported')
            dispatch(setDefaultFilters())
            dispatch(setHideFilters(true))
            // @ts-ignore
            return
        }

        if (!loggedIn && visitedGeo) {
            dispatch(setDefaultFilters())
        }
        if (visitedGeo && geolocationVisitedInThisBrowser === 'true')
            navigator.geolocation.getCurrentPosition(getPosition, onError)
        updateTimeout.current = setTimeout(() => setUpdate(Number(new Date())), 180000)

        return () => {
            clearTimeout(updateTimeout.current)
        }
    }, [loggedIn, update, shareLocation, visitedGeo])
    return { ...position, error }
}
export default usePosition
