import { useContext, useEffect } from 'react'
import { FilterButtonContext } from './FilterButtonContext'
import { scrollToTop } from '../../../store/common/commonSlice'
import { useDispatch } from 'react-redux'

export const useFilterButton = (
    popupState: boolean,
    setPopupState: React.Dispatch<boolean>,
    isNecessaryToScrollTop = false
) => {
    const { setFilterButton, filterButton, setIsNecessaryToShowFilters } = useContext(FilterButtonContext)
    const dispatch = useDispatch()

    useEffect(() => {
        setIsNecessaryToShowFilters(true)
        return () => setIsNecessaryToShowFilters(false)
    }, [])

    useEffect(() => {
        if (!popupState) setFilterButton(false)
        if (filterButton) {
            setPopupState(true)
            if (isNecessaryToScrollTop) dispatch(scrollToTop())
        }
    }, [filterButton, popupState])
}
