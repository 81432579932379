import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { advState } from './advTyping'
import { getAdvUsers } from '../../api/adv'
const initialState: advState = {
    users: [],
    chatScriptUrl: ''
}

export const fetchAdv = createAsyncThunk('fetchAdvLsit', async () => {
    return await getAdvUsers()
})

const advSlice = createSlice({
    name: 'adv',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAdv.fulfilled, (state, action) => {
            state.users = [...action.payload[0].users]
            state.chatScriptUrl = action.payload[0].chatScriptUrl
        })
        // builder.addCase(fetchAdv.rejected, rejectedAdvReducer)
        // builder.addCase(fetchAdv.pending, fulfilledAdvReducer)
    }
})

export default advSlice.reducer
