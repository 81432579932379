import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { pageLoaded } from '../store/common/commonSlice'

const useLoadedPage = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(pageLoaded())
        // eslint-disable-next-line
    }, [location])
}

export default useLoadedPage
