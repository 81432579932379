import React from 'react'
import { createLocalizedPath } from '../../utils/localizationHelpers'
import paths from '../../paths'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const MenuLinks = () => {
    const [t] = useTranslation()
    return (
        <div className="menuSection">
            <div className="menuSection__title">{t('Menu')}</div>
            <div className="menuSection__items">
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath(paths.index.path) }}>
                    {t('Home')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath(paths.profiles.path) }}>
                    {t('Profiles')}
                </Link>
                <Link
                    className="menuSection__item"
                    to={{ pathname: createLocalizedPath(paths.indexCertifiedFemAndCouple.path) }}
                >
                    {t('Certified females and couples')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath('/tags/') }}>
                    {t('Search content')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath(paths.indexBestPhotos.path) }}>
                    {t('The best photos')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath(paths.geo.path) }}>
                    {t('Around me')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: '/forum/' }} target="_blank">
                    {t('Forum')}
                </Link>
                <Link className="menuSection__item" to={{ pathname: createLocalizedPath(paths.contact.path) }}>
                    {t('Contact')}
                </Link>
                <a
                    className="menuSection__item"
                    href={'https://www.bobvoyeur.com/arphp/'}
                    target="_blank"
                    rel="noreferrer"
                >
                    {t('Links')}
                </a>
                <Link className="menuSection__item" to={{ pathname: paths.cgu.path }}>
                    {t('CGU')}
                </Link>
            </div>
        </div>
    )
}

export default MenuLinks
