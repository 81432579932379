import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NOTIFICATIONS_DESCRIPTION } from './config'

import cls from './newMessage.module.sass'
import { useDispatch } from 'react-redux'
import { deleteNotification } from '../../../store/member/memberSlice'
import { SingleActiveNotification } from '../../../store/member/memberTypings'
import { useHistory, useLocation } from 'react-router-dom'
import { createLocalizedPath } from '../../../utils/localizationHelpers'

interface Props {
    notification: SingleActiveNotification
}

const NewNotification = ({ notification }: Props) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const [isNotificationClosing, setIsNotificationClosing] = useState(false)

    const handleClosingNotification = (e: React.MouseEvent<HTMLImageElement>) => {
        e.stopPropagation()
        setIsNotificationClosing(true)
        setTimeout(() => dispatch(deleteNotification(notification)), 500)
    }

    const handleRedirectOnCLick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        dispatch(deleteNotification(notification))
        if (notification.url) history.push(createLocalizedPath(notification.url))
        switch (notification.type) {
            case 'message':
                history.push(createLocalizedPath(`/membre/pm/conversations?pm=${notification.senderId}`))
                break
            case 'premium':
                history.push(createLocalizedPath(`/membre/historique-des-paiements/`))
                break
            case 'guestbook':
                history.push(createLocalizedPath(`/membre/livre-d-or/`))
                break
            case 'gift':
                history.push(createLocalizedPath(`/membre/cadeaux-virtuels/`))
                break
            case 'follower':
                history.push(createLocalizedPath(`/membre/followers/`))
                break
            case 'premiumEnds':
                history.push(createLocalizedPath(`/membre/payment`))
                break
            case 'premiumEnded':
                history.push(createLocalizedPath(`/membre/payment`))
                break
            default:
                console.log(`unknown type: ${notification.type}`)
        }
    }

    const RenderNotification = () => {
        switch (notification.type) {
            case 'message':
                return (
                    <p>
                        {t('New message from')}
                        <span>{notification.senderNick}</span>
                    </p>
                )
            case 'guestbook':
                return (
                    <p>
                        <Trans
                            i18nKey={'New guestbook message'}
                            values={{
                                userNick: notification.senderNick
                            }}
                        >
                            New guestbook message from <span>{notification.senderNick}</span>
                        </Trans>
                        <p>{notification.text}</p>
                    </p>
                )
            case 'gift':
                return (
                    <p>
                        <span>{notification.senderNick}</span>
                        {t('sent you a gift')}
                        <p>{notification.text}</p>
                    </p>
                )
            case 'follower':
                return (
                    <p>
                        <span>{notification.senderNick}</span>
                        {t('starts following you')}
                    </p>
                )
            case 'newContribution':
                return (
                    <p>
                        <span>{notification.senderNick}</span>
                        {t('added a new contribution')}
                    </p>
                )
            case 'premium':
                return (
                    <p>
                        <Trans
                            i18nKey={'Premium gifted'}
                            values={{
                                userNick: notification.senderNick,
                                amount: notification.duration
                            }}
                        >
                            <span>{notification.senderNick}</span> gave you {notification.duration} days of Premium
                        </Trans>
                    </p>
                )
            case 'premiumEnds':
                return (
                    <p>
                        <Trans
                            i18nKey={'Premium ends'}
                            values={{
                                amount: notification.duration
                            }}
                        >
                            Your Premium membership expires in {notification.duration} days. <span>Renew now</span> to
                            keep accessing the Premium content.
                        </Trans>
                    </p>
                )
            case 'premiumEnded':
                return (
                    <p>
                        <Trans i18nKey={'Premium ended'}>
                            Your Premium membership has expired. <span>Renew now</span> to restore your access to the
                            Premium content.
                        </Trans>
                    </p>
                )
            default:
                return <></>
        }
    }

    useEffect(() => {
        if (notification.type === 'message' && location.search.includes(`pm=${notification.senderId}`))
            dispatch(deleteNotification(notification))
    }, [location])
    if (notification.type === 'message' && location.search.includes(`pm=${notification.senderId}`)) return <></>

    return (
        <div
            className={isNotificationClosing ? `${cls['newNotification']} ${cls['closing']}` : cls['newNotification']}
            onClick={handleRedirectOnCLick}
        >
            {notification.cover ? (
                <div className={cls['notificationRow']}>
                    <img className={cls['cover']} src={notification.cover} />
                </div>
            ) : (
                <></>
            )}
            <div className={cls['notificationRow']}>
                <div className={cls['notification']}>
                    <img
                        src={notification.image || NOTIFICATIONS_DESCRIPTION[notification.type].icon}
                        alt="New message"
                        className={cls['notificationIcon']}
                    />
                    {RenderNotification()}
                </div>
                <img
                    src={require('./../../../assets/ic-close.svg')}
                    alt="New message"
                    className={cls['closeIcon']}
                    onClick={handleClosingNotification}
                />
            </div>
        </div>
    )
}

export default NewNotification
