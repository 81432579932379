import React, { Suspense, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import VideoCallRequest from './videoCallRequest'
import PaginationLoader from '../../ui/paginationLoader/PaginationLoader'
import NewVideoCall from './newVideoCall'
import CallEnded from './CallEnded'
import { RootState } from '../../../store/rootReducer'
import { PmCommonType } from '../../../store/common/commonSliceTyping'
import './popupNotifications.sass'
import NewNotification from './NewNotification'
import { MemberState } from '../../../store/member/memberTypings'

import { Member } from '../../../store/commonTypes'
import findIndex from 'lodash/findIndex'
import { Trans, useTranslation } from 'react-i18next'
import { clearNotifications } from '../../../store/member/memberSlice'

const MiniVideo = React.lazy(() => import('./MiniVideo'))

const PopupNotifications = () => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const pm_common = useSelector<RootState, PmCommonType>((state) => state.common.pm_common)
    const user = useSelector<RootState, Member | null>((state) => state.member.user)
    const loggedIn = useSelector<RootState, boolean>((state) => state.member.loggedIn)

    const location = useLocation()

    const { activeNotifications } = useSelector<RootState, MemberState>((state) => state.member)
    const [showNotifications, setShowNotifications] = useState(false)
    const [renderedNotifications, setRenderedNotifications] = useState([])
    const [newNotification, setNewNotification] = useState(null)

    const isShowingVideo = () =>
        pm_common.userIdVideoChatRequest &&
        user &&
        user.id !== pm_common.userIdVideoChatRequest &&
        (pm_common.state === 'active' || pm_common.state === 'connecting') &&
        !location.search.includes(`pm=${pm_common.userIdVideoChatRequest}`)

    const isNewCall = () =>
        pm_common.userIdVideoChatRequest &&
        user &&
        user.id !== pm_common.userIdVideoChatRequest &&
        pm_common.state === 'new' &&
        !pm_common.incoming

    const isEnded = () =>
        pm_common.userIdVideoChatRequest &&
        user &&
        user.id !== pm_common.userIdVideoChatRequest &&
        pm_common.state === 'ended' &&
        pm_common.ended_reason

    const timeoutRef = useRef(null)

    useEffect(() => {
        if (
            findIndex(renderedNotifications, activeNotifications[activeNotifications.length - 1]) === -1 &&
            !showNotifications
        ) {
            setNewNotification(activeNotifications[activeNotifications.length - 1])
        } else {
            setNewNotification(null)
        }

        if (!activeNotifications.length) {
            setShowNotifications(false)
        } else {
            if (document.body.clientWidth > 480) setShowNotifications(true)
        }
        setRenderedNotifications((prev) => prev.concat(activeNotifications[activeNotifications.length - 1]))

        if (timeoutRef.current) clearTimeout(timeoutRef.current)
        timeoutRef.current = setTimeout(() => {
            setNewNotification(null)
        }, 3000)
    }, [activeNotifications])

    return (
        <>
            <div className={isShowingVideo() ? 'popup-notifications showVideo' : 'popup-notifications'}>
                {pm_common.userIdVideoChatRequest &&
                    user &&
                    user.id !== pm_common.userIdVideoChatRequest &&
                    (pm_common.sessionHiddenCallers.length
                        ? !pm_common.sessionHiddenCallers.includes(pm_common.userIdVideoChatRequest)
                        : pm_common.incoming && pm_common.state == 'new') && (
                        <VideoCallRequest userId={pm_common.userIdVideoChatRequest} call_id={pm_common.call_id} />
                    )}
                {isShowingVideo() &&
                    (pm_common.sessionHiddenCallers.length
                        ? !pm_common.sessionHiddenCallers.includes(pm_common.userIdVideoChatRequest)
                        : true) && (
                        <Suspense fallback={<PaginationLoader />}>
                            <MiniVideo />
                        </Suspense>
                    )}
                {isNewCall() && <NewVideoCall userId={pm_common.userIdVideoChatRequest} call_id={pm_common.call_id} />}
                {isEnded() && <CallEnded userId={pm_common.userIdVideoChatRequest} call_id={pm_common.call_id} />}

                {loggedIn && showNotifications && activeNotifications.length ? (
                    <div className={'notificationsBlock'}>
                        {activeNotifications.slice(-5).map((notification) => (
                            <NewNotification notification={notification} key={notification.id} />
                        ))}
                    </div>
                ) : (
                    <></>
                )}

                {showNotifications && activeNotifications.length > 1 ? (
                    <div
                        className={'closeAll'}
                        onClick={() => {
                            dispatch(clearNotifications())
                        }}
                    >
                        <Trans
                            i18nKey={'Close all notifications'}
                            values={{
                                amount: activeNotifications.length
                            }}
                        >
                            Close all {activeNotifications.length} notifications
                        </Trans>
                    </div>
                ) : (
                    <></>
                )}
                {loggedIn && activeNotifications.length ? (
                    <div
                        className={'notificationsTrigger'}
                        onClick={() => {
                            setShowNotifications((prev) => !prev)
                            setNewNotification(null)
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>
            {activeNotifications.length && newNotification && !showNotifications ? (
                <div className={'singleMobileNotification'}>
                    {activeNotifications.length && (
                        <NewNotification notification={newNotification} key={newNotification.id} />
                    )}
                </div>
            ) : (
                <></>
            )}
        </>
    )
}

export default PopupNotifications
