import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After, getSerializedData } from '@jaredpalmer/after'
import { FilterButtonProvider } from './provider/FilterButtonProvider'
// import * as Sentry from '@sentry/react'

import routes from './routes'
import i18next from './i18next/index'

import createStore from './store/store'
import './client.sass'
import App from './pages/app'
import ErrorBoundary from './provider/ErrorBoundary/ErrorBoundary'

const preloadedState = getSerializedData('preloaded_state')
const store = createStore(preloadedState)

// Sentry.init({
//     dsn: process.env.RAZZLE_SENTRY_DSN_PRODUCTION
//         ? process.env.RAZZLE_SENTRY_DSN_PRODUCTION
//         : process.env.RAZZLE_SENTRY_DSN,
//     tracesSampleRate: 1
// })

ensureReady(routes).then((data) =>
    renderMethod(
        <BrowserRouter>
            <Provider store={store}>
                <I18nextProvider i18n={i18next}>
                    <FilterButtonProvider>
                        <ErrorBoundary>
                            <App>
                                <After
                                    // @ts-ignore
                                    data={data}
                                    routes={routes}
                                    store={store}
                                />
                            </App>
                        </ErrorBoundary>
                    </FilterButtonProvider>
                </I18nextProvider>
            </Provider>
        </BrowserRouter>,
        document.getElementById('root')
    )
)

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate
if (module.hot) {
    module.hot.accept()
}
