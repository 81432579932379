import React from 'react'
import { useTranslation } from 'react-i18next'

import cls from './notFound.module.sass'
import usePageLoaded from '../../hooks/usePageLoaded'

const NotFound = () => {
    usePageLoaded()
    const [t] = useTranslation()

    return (
        <div className={cls['notFoundText']}>
            <p>404</p>
            <p>{t('The page you were looking for was not found')}</p>
        </div>
    )
}

export default NotFound
