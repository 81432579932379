import { getWrapper } from './axios'
import {
    FetchGetTag,
    FetchMaterialList,
    FetchSearchList,
    FetchTags,
    FetchTagsList,
    GetTagType,
    MaterialResponse,
    TagsState
} from '../store/tags/tagsTyping'

export const getSearchResults = (params: FetchTags): Promise<TagsState> => {
    return getWrapper('/tags/search', { params }) as Promise<TagsState>
}

export const getTagsList = (params: FetchTagsList): Promise<TagsState> => {
    return getWrapper('/tags/list', { params }) as Promise<TagsState>
}

export const getSearchList = (params: FetchSearchList): Promise<TagsState> => {
    return getWrapper('/tags/searches/list', { params }) as Promise<TagsState>
}

export const getTag = (params: FetchGetTag): Promise<GetTagType> => {
    return getWrapper('/tags/get', { params }) as Promise<GetTagType>
}

export const getListOfMaterial = (params: FetchMaterialList) => {
    return getWrapper('/list/material', { params }) as Promise<MaterialResponse>
}
