import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { getProfiles } from '../../api/profiles'
import { ProfilesState, FetchProfiles, FetchProfilesPayload } from './profilesTypings'

import findIndex from 'lodash/findIndex'

const initialState: ProfilesState = {
    list: [],
    total: 0,
    loading: false
}

export const fetchProfiles = createAsyncThunk('fetchProfiles', async (params: FetchProfiles) => {
    return await getProfiles(params)
})

const profilesSlice = createSlice({
    name: 'profiles',
    initialState,
    reducers: {
        clearProfilesList(state) {
            state.list = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfiles.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchProfiles.fulfilled, (state, action: PayloadAction<FetchProfilesPayload>) => {
            state.loading = false
            state.total = action.payload.total
            if (findIndex(state.list, action.payload.list[0]) === -1)
                state.list = state.list.concat(action.payload.list)
        })
        builder.addCase(fetchProfiles.rejected, (state) => {
            state.loading = false
        })
    }
})
export const { clearProfilesList } = profilesSlice.actions
export default profilesSlice.reducer
