/* eslint i18next/no-literal-string: 0 */
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

import i18next, { getLangPrefix, switchLang } from '../../../i18next'

import './langLinks.sass'

const LangLinks: React.FunctionComponent = () => {
    const location = useLocation()

    const handleLangChange = (lang: string) => {
        let currentUrl = location.pathname
        if (currentUrl.includes('/ru/')) currentUrl = currentUrl.replace('/ru/', '/')
        if (currentUrl.includes('/en/')) currentUrl = currentUrl.replace('/en/', '/')
        return {
            pathname: getLangPrefix(lang) + currentUrl,
            state: { ...location.state, changeLang: true },
            search: location.search
        }
    }

    return (
        <div className="langLinks">
            <Link
                to={handleLangChange('fr')}
                onClick={() => switchLang('fr')}
                className={`langLinks__item ${
                    i18next.language !== 'en' && i18next.language !== 'ru' ? 'langLinks__item_active' : ''
                }`}
            >
                Fr
            </Link>
            <Link
                to={handleLangChange('en')}
                onClick={() => switchLang('en')}
                className={`langLinks__item ${i18next.language === 'en' ? 'langLinks__item_active' : ''}`}
            >
                En
            </Link>
            <Link
                to={handleLangChange('ru')}
                onClick={() => switchLang('ru')}
                className={`langLinks__item ${i18next.language === 'ru' ? 'langLinks__item_active' : ''}`}
            >
                Ru
            </Link>
        </div>
    )
}

export default LangLinks
