import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { RootState } from '../store/rootReducer'
// @ts-ignore
import { FullPmState } from 'pm-js/types/pm'
import { isUserPro } from '../utils/isUserPro'

const usePmRequest = () => {
    const dispatch = useDispatch()
    const { user, loggedIn } = useSelector((state: RootState) => state.member)
    const pm = useSelector<RootState, FullPmState>((state) => state.pm)
    const isFirstTime = useRef(true)

    useEffect(() => {
        if (loggedIn && Object.values(pm.conversations).length && isFirstTime.current && isUserPro(user.member_type)) {
            isFirstTime.current = false
            Object.keys(pm.conversations).forEach((key) => {
                if (pm.conversations[key].generalStatus === 'requested_by_me') {
                    dispatch({ type: 'PM_CANCEL_PM_REQUEST', conversation_uid: Number(key) })
                    setTimeout(() => {
                        dispatch({ type: 'PM_PERFORM_PM_REQUEST', conversation_uid: Number(key) })
                    }, 100)
                }
            })
        }
    }, [pm.status])
}
export default usePmRequest
