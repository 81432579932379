import i18next from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
// @ts-ignore
import intervalPlural from 'i18next-intervalplural-postprocessor'

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(intervalPlural)
    .init({
        resources: {
            fr: {
                translation: {
                    // common
                    Nick: 'Pseudo',
                    Sex: 'Genre',
                    Male: 'Homme',
                    male: 'Homme',
                    Female: 'Femme',
                    female: 'Femme',
                    Transvestite: 'Travesti',
                    'Female or couple': 'Femme ou couple',
                    'Females or couples': 'Femmes ou couples',
                    'Only photo': 'Photo uniquement',
                    'Only video': 'Vidéo uniquement',
                    'Females and males': 'Femmes et hommes',
                    'Males only': 'Hommes uniquement',
                    Couple: 'Couple',
                    couple: 'Couple',
                    years: 'ans',
                    User: 'Utilisateur',
                    Location: 'Localisation',
                    Cancel: 'Annuler',
                    'Clear filters': 'Effacer les filtres',
                    'All content': 'Tout le contenu',
                    'Search content': 'Chercher du contenu',
                    Search: 'Chercher',
                    Activate: 'Activer',
                    'Log in': 'Connexion',
                    'Sign up': 'Enregistrement',
                    'Password reset': 'Réinitialiser le mot de passe',
                    Material: 'Contenu',
                    Period: 'Période',
                    Contributors: 'Contributeurs',
                    Disable: 'Désactiver',
                    Expiration: "Date d'expiration : ",
                    Users: 'Utilisateurs',
                    Select: 'Sélectionner',
                    'No options': "Pas d'option",
                    'The page you were looking for was not found': "La page que vous recherchiez n'a pas été trouvée",

                    // auth forms
                    Enter: 'Entrer',
                    Exit: 'Sortir',
                    loginError: `Vérifiez que votre identifiant et votre mot de passe sont corrects`,
                    Password: 'Mot de passe',
                    forgotPassword: 'Mot de passe oublié?',
                    'Nick is required': 'Le pseudo est requis',
                    'Password is required': 'Le mot de passe est requis',
                    'Password confirmation': 'Confirmer le mot de passe',
                    'Not specified': 'Non spécifié',
                    'Photo and Video': 'Photo et vidéo',
                    Photo: 'Photo',
                    forgotPasswordHelpText:
                        'Entrez votre adresse émail ou pseudo pour recevoir immédiatement votre mot de passe par émail',
                    'Current password': 'Mot de passe actuel',
                    'New password': 'Nouveau mot de passe',
                    'New password again': 'Confirmez le nouveau mot de passe',
                    'Activate your account to view this photo': 'Activez votre compte pour voir cette photo.',
                    'Activate your account to watch the rest of the video':
                        'Activez votre compte pour regarder la vidéo en entier.',
                    'You need to activate your account to use this feature':
                        'Vous devez activer votre compte pour utiliser cette fonction.',

                    // menu
                    Menu: 'Menu',
                    Home: 'Accueil',
                    Forum: 'Forum',
                    Wiki: 'Le Wiki',
                    Links: 'Liens',
                    CGU: 'CGU',
                    Contact: 'Contacter Bob',
                    Information: 'Information',
                    'Webcamchat on BobTV': 'Tchat webcam bobTV',
                    'Les sites de Bob': 'Les sites de Bob',
                    'My membership Premium': 'Mon abonnement Premium',
                    'Single period, without automatic rebilling': 'Période unique, sans renouvellement automatique,',
                    'Immediate access': 'Accès immédiat!',
                    'Access Premium features': 'Accéder aux fonctions Premium',
                    'My virtual gifts': 'Mes cadeaux virtuels',
                    'Buy more Premium': 'Prolonger mon Premium',
                    activationFormText:
                        "Entrez le code d'activation envoyé à votre adresse " +
                        `<1>{{email}}</1> ou cliquez sur le lien d'activation contenu dans cet émail. Si vous ne recevez pas cet émail immédiatement, vérifiez votre dossier "spam" et marquez le comme "non-spam".`,
                    Reload: 'Actualiser',
                    'Oops, something went wrong': 'Oups, quelque chose ne fonctionne pas.',
                    'Try to reload the page': "Essayez d'actualiser la page.",
                    'Delete my account': 'Supprimer mon compte',
                    'You can request deletion':
                        'Supprimez votre compte <span>{{nick}}</span> en cliquant sur le bouton ci-dessous.',
                    'All data in this account will be deleted':
                        'Toutes les données rattachées à ce compte seront rapidement supprimées (abonnement, photos, vidéos, adresses émail, numéro de téléphone...)',
                    'Your request will not be cancelable':
                        "ATTENTION : Cette action est irréversible, il ne vous sera plus possible d'accéder à ce compte.",

                    //payment
                    'Buy Premium Membership': 'Achetez un abonnement Premium',
                    'Give Premium Membership': 'Offrez un abonnement Premium',
                    'Membership history': 'Historique des abonnements',
                    'Choose your Premium plan': 'Choisissez la durée de votre abonnement Premium',
                    "Choose what Premium plan you'll give to":
                        "Choisissez la durée de l'abonnement Premium que vous allez offrir à",
                    'More information about Functions Premium': 'Plus de détails concernant les fonctions Premium',
                    Functions: 'Fonctions',
                    contributionsAndMaterialsCount:
                        "pour visiter toutes les archives : <1>{{contributionsCount}}</1> contributions, <3>{{materialCount}}</3> vidéos et photos d'amateurs",
                    'to view the contributions of Hot section, Premium themes':
                        'pour visionner les contributions des sections Hot, des thèmes Premium',
                    'to view all videos of long duration': 'pour visionner toutes les vidéos longues durées',
                    'to download and save videos of original quality':
                        'pour télécharger et sauvegarder toutes les vidéos',
                    'to view full size photos with greater size 1280x960':
                        'pour visionner les photos disponibles en taille 1280x960',
                    'to upload 40 photos to your profile': 'pour télécharger 40 photos sur votre page profil',
                    'to view photos in Premium albums of all members':
                        'pour visionner les photos des albums Premium de tous les membres',
                    'Secure server, payment page is encrypted with 128 Bit SSL':
                        'Serveur sécurisé, protocole 3-D Secure, page de paiement cryptée SSL 256-bit.',
                    'Card number': 'Numéro de carte',
                    'Expiration date': "Date d'expiration :",
                    'Security code': 'Code de sécurité :',
                    'Enter security code': 'Entrez le code de sécurité',
                    'This code consists of the last 3 digits at the back side of most credit cards':
                        'Le cryptogramme est composé des 3 derniers chiffres du numéro présent au dos de la plupart des cartes bancaires.',
                    'Your email address': 'Votre adresse émail :',
                    'Payment first line':
                        "Votre confirmation de paiement vous sera envoyée à cette adresse email. Prenez soin d'indiquer une adresse valide afin de recevoir ce message. ",
                    'Payment second line':
                        'Consultez votre dossier SPAM si vous ne recevez pas ce message dans votre boite de réception ',
                    inbox: 'de reception',
                    Confirm: 'Confirmer votre paiement',
                    'should be chosen': 'doit être sélectionné',
                    'Credit card number is required': 'Le numéro de carte de crédit est requis',
                    'Invalid credit card number': 'Numéro de carte de crédit invalide',
                    'You can attach a message for the recipient, if you want':
                        'Vous pouvez adresser un message au destinataire, si vous le souhaitez',
                    'Replenish your wallet': 'Créditer mon porte monnaie virtuel',
                    Membership: 'Abonnement',
                    singlePaymentText:
                        '<0>Paiement unique, sans renouvellement automatique.</0> Vous serez débité une <2>seule</2> fois.',
                    'Enter a card number': 'Indiquer un numéro de carte',
                    'MM/YY': 'MM/AA',
                    'Card number is invalid': "Le numéro de carte n'est pas valide",
                    'Enter an expiry date': "Indiquer une date d'expiration",
                    'Expiry month must be between 01 and 12': "Le mois d'expiration doit être entre 01 et 12",
                    'Expiry year cannot be in the past': "L'année d'expiration ne peut pas être passée",
                    'Expiry date cannot be in the past': "La date d'expiration ne peut pas être passée",
                    'Expiry date is invalid': "La date d'expiration n'est pas valide",
                    'Enter a CVC': 'Indiquer un CVC',
                    'CVC is invalid': "Le CVC n'est pas valide",

                    // index
                    'Index title': "Bob Voyeur est la référence de l'exhibe amateur depuis 2003",
                    filters: 'filtres',
                    Filters: 'Filtres',
                    'Apply filters': 'Appliquer les filtres',
                    'Certified and non-certified': 'Certifiés et non-certifiés',
                    'Only Free': 'Gratuites uniquement',
                    'Last week': 'Semaine précédente',
                    'Last month': 'Mois précédent',
                    'Last year': `Année précédente`,
                    'All time': 'Autres dates',
                    'Amateur contributions': "Contributions d'amateurs",
                    'Videochat rooms to exhibit on webcam for amateur libertines and voyeurs':
                        'Salles de visiochat pour exhib en cam entre amateurs libertins ou voyeurs',
                    'Nudity and sex webcams are accepted on chat rooms':
                        ' La nudité et les rapports sexuels en cam sont acceptés sur les salons de tchat',
                    'Load more': 'Charger plus',
                    Online: 'En ligne',
                    Offline: 'Hors ligne',
                    'Certified info': `Plusieurs centaines d'amatrices et de couples amateurs adultes sont déjà certifiés dans la communauté de Bob Voyeur.
                Pour être, vous aussi, amateur certifié, il suffit de joindre à votre contribution une photo qui montre que vous êtes un authentique couple amateur ou une authentique amatrice fan de Bob Voyeur.
                Cette photo doit impérativement présenter un modèle féminin ainsi que le nom de notre site préféré. Vous pouvez donc inscrire "Bob Voyeur" sur une feuille de papier, sur votre peau ou poser devant l'écran de votre ordinateur... Soyez imaginatif ;-)`,
                    'Best info': `Les meilleures photos de Bob est une sélection très personnelle des photos d'amateurs publiées sur Bob Voyeur. Ces photos d'exhibition envoyées par des hommes, des amatrices et des couples amateurs représentent parfaitement l'esprit Bob Voyeur. Les photos ont été sélectionnées pour l'érotisme qu'elles dégagent, la qualité de l'image et le choix du décor.`,
                    Locate: 'Localiser',
                    'Certified females and couples': 'Femmes et couples certifiés',
                    'The Best submitted photos': 'Les meilleures photos',
                    'Female and couple photos': 'Photos femmes et couples',
                    'Female and couple videos': 'Vidéos femmes et couples',
                    'Male photos and videos': 'Photos et vidéos hommes',
                    'The best photos': 'Les meilleures photos',

                    // profiles search
                    Profiles: 'Les membres',
                    Profiles_page: 'Les membres de Bob Voyeur',
                    'Profiles info': `Utilisez cet outil de recherche pour filter la liste complète des utilisateurs amateurs de Bobvoyeur`,
                    'With album photo': 'Avec photos',
                    'Certified amateurs': 'Amateurs certifiés',
                    'With contributions': 'Avec contributions',
                    'Online within 10 days': 'Connectés dans les 10 derniers jours',
                    'User nick': 'Chercher un pseudo',
                    Gender: 'Genre',
                    Age: 'Âge',
                    Country: 'Pays ',
                    Region: 'Région ',
                    'Show more filters': 'Afficher plus de filtres',
                    'Only Certified': 'Amateurs certifiés uniquement',
                    Profile: 'Profil',

                    // profile
                    'Last online': 'Dernière connexion le ',
                    'time format': 'DD/MM/YYYY [à] HH:mm',
                    views: 'vues',
                    followers: 'abonnés',
                    block: 'Bloquer',
                    unblock: 'Débloquer',
                    'Public album': 'Album public',
                    'All photos': 'Toutes les photos',
                    Contributions: 'Contributions',
                    'All contributions': 'Toutes les contributions',
                    Guestbook: "Livre d'or",
                    'Add a message': 'Ajouter un message',
                    About: 'Description ',
                    Change: 'Modifier',
                    'Change email': "Changer d'adresse émail",
                    'Change password': 'Changer de mot de passe',
                    'Allow private messages': 'Accepter les messages privés',
                    'Tell something about you': 'Parlez de vous',
                    Save: 'Sauvegarder',
                    Reset: 'Annuler',
                    'All of my guestbook': 'Tous mes messages',
                    'All guestbook messages': 'Tous les messages',
                    'Current email address': 'Adresse émail actuelle',
                    'Photos of your public album can be viewed by all registered visitors':
                        'Les photos de votre album public sont vues par tous les utilisateurs connectés.',
                    'Photos of your Premium album can be viewed by Premium members only':
                        'Les photos de votre album Premium sont vues uniquement par les membres Premium.',
                    'Your private album is visible only to the members of your friends list':
                        'Les photos de votre album privé sont vues par les utilisateurs qui vous suivent et que vous suivez en retour.',
                    'A text description': 'Une description de chaque photo sera très appréciée par vos visiteurs.',
                    'of 20': ' de 20',
                    'of 40': ' de 40',
                    public: 'public',
                    private: 'privé',
                    Give: 'Offrir',
                    'My albums': 'Mes albums',
                    'Private album': 'Album Privé',
                    'Premium album': 'Album Premium',
                    'All virtual gifts': 'Tous les cadeaux',
                    'All my virtual gifts': 'Tous mes cadeaux virtuels',
                    'Participations in the forum': 'Participations au forum',
                    'My participations in the forum': 'Participations au forum',
                    'My participations in the forum button': 'Toutes les participations',
                    'I have not yet participated in the forum':
                        "Je n'ai pas encore participé au <a href='/forum/'>forum</a>.",

                    //geo
                    'Around me': 'Autour de moi',
                    'Search radius (km)': 'Rayon de recherche (km)',
                    'Move the cursor to display my position with more or less precision':
                        'Déplacer le curseur pour afficher ma position avec plus ou moins de précision :',
                    'Share my position on the map': 'Partager ma position sur la carte',
                    'My mood': 'Mon humeur :',
                    'Sort by': 'Trier par:',
                    'Near to me': 'Près de moi',
                    'Change your humor': 'Mon humeur du moment',
                    Humor: 'Précisez votre recherche ...',
                    'Users in table': '(1)[utilisateur autour de moi];(3)[utilisateurs autour de moi]',
                    map_distance: 'à {{count}}m près.',
                    'exact position': 'position exacte',
                    'approximate position': 'position approximative',
                    'Login to estimate distances': 'Connectez-vous pour évaluer les distances.',
                    'Share your position on the map to estimate distances':
                        'Partagez votre position sur la carte pour évaluer les distances.',
                    'Enable geolocation in your browser settings to estimate distances':
                        'Activez la géolocalisation dans les paramètres de votre navigateur pour partager votre position et évaluer les distances.',
                    'Higher precision only available on mobile devices':
                        'Précision accrue disponible uniquement sur les appareils mobiles.',
                    'geo time format': '[Le] DD/MM [à] HH:mm',
                    'Exact position on': 'Position exacte ',
                    'Approximate position on': 'Position approximative ',
                    exactly: 'exactement',
                    approximately: 'environ',
                    'Your location': 'Votre emplacement',
                    //FAQ geo
                    'Authorize the bobvoyeur-com site to access your location in your browser':
                        'Dans votre navigateur, autorisez le site bobvoyeur.com à accéder à votre localisation.',
                    'Share your position on the map to estimate the distances between you and other members':
                        'Partagez votre position sur la carte pour évaluer les distances entre vous et les autres membres.',
                    ThirdLineFAQGeo:
                        'Déplacez le curseur pour afficher votre position avec plus ou moins de précision. Si vous utilisez votre téléphone, vous pouvez partager votre position exacte grâce au GPS de votre appareil. Si vous utilisez un ordinateur, votre position sur la carte sera approximative.',
                    'Write a short mood text to describe your interests':
                        "Éditez un petit texte d'humeur pour décrire votre recherche.",
                    FivesLineFAQGeo:
                        'La carte affiche les membres en ligne ou qui se sont connectés à ce service dans les 7 derniers jours. Leur position est exacte ou approximative en fonction de leur choix de précision.',

                    // blacklist
                    'My blacklist': 'Mes blocages',
                    'My blocked users': "Les membres que j'ai bloqué",
                    'Blacklist description': 'Les membres que vous bloquez ne peuvent plus vous envoyer de message.',
                    Duration: 'Durée',
                    Reason: 'Raison ',
                    Block: 'Bloquer',
                    Unblock: 'Débloquer',
                    'Are you sure?': 'En êtes-vous certain?',
                    'Do you want to unblock': 'Voulez-vous débloquer',
                    'Describe your reason': 'Raison du blocage',
                    'For how long?': 'Durée du blocage :',
                    '24 hours': '24 heures',
                    Permanent: 'Définitive',
                    'Add to blacklist': 'Bloquer',
                    'Why would you add to your black list': 'Pour quelle raison voulez-vous bloquer',
                    'There are no users in your blacklist': "Vous n'avez bloqué personne",
                    'List of blocked users': 'Liste des membres bloqués',
                    'Adding date': "Date d'ajout",
                    'Time of blocking': 'Durée du blocage',

                    // following
                    follow: 'Suivre',
                    unfollow: 'Ne plus suivre',
                    Following: 'Abonnements',
                    Followers: 'Abonnés',
                    'Follow user': "Suivre l'utilisateur",
                    'Search by name': 'Recherche par pseudo',
                    'My followers': 'Mes abonnés',
                    'My followees': 'Mes abonnements',

                    'Members I follow': 'Les membres que je suis',
                    'have no followers yet': " pas d'abonné pour le moment",
                    'have no followed users': " pas d'abonnement pour le moment",
                    'You have no followers yet': "Vous n'avez pas d'abonné pour le moment",
                    'There are no followed users': "Il n'y a pas d'abonné",

                    // messages
                    'My messages': 'Mes messages',
                    'Send a message': 'Envoyer un message',
                    Messages: 'Messages',
                    'My requests': 'Mes demandes de MP',
                    'Private messages': 'Messages Privés',
                    'New message': 'Nouveau message',

                    // guestbook
                    'My guestbook': "Mon livre d'or",
                    'You dont have any guestbook messages': "Vous n'avez pas de message",
                    ' doesnt have any guestbook messages': " n'a pas de message",
                    'Disable my Guestbook': "Désactiver mon livre d'or",
                    'Add message to Guestbook of': "Ajoutez un commentaire au livre d'or de",

                    // wallet

                    'My wallet': 'Mon porte monnaie virtuel',
                    'Virtual gifts': 'Cadeaux virtuels',
                    'Pending gifts': 'Cadeaux en attente',
                    History: 'Historique',
                    Replenish: 'Créditer',
                    points: 'jetons',
                    tokens: 'jetons',
                    days: 'jours',
                    'Your virtual wallet contains points': 'Votre porte-monnaie virtuel contient des jetons.',
                    'You can purchase these points by crediting your virtual wallet by a secure online payment':
                        'Vous pouvez acheter ces jetons en créditant votre porte monnaie virtuel par un paiement en ligne sécurisé.',
                    'The points accumulated in your virtual wallet have unlimited validity':
                        'Les jetons cumulés dans votre porte-monnaie virtuel ont une durée de validité illimitée.',
                    'You can use these points to offer virtual gifts to any bobVoyeur user by your choice':
                        'Vous pouvez utiliser ces jetons pour offrir des cadeaux virtuels aux utilisateurs de votre choix.',
                    "You can buy points and give virtual gifts even if you're not Premium member":
                        "Vous pouvez acheter des jetons et offrir des cadeaux virtuels même si vous n'êtes pas membre Premium.",
                    'to give to': 'pour offrir à',
                    Date: 'Date',
                    Credit: 'Crédit',
                    Debit: 'Débit',
                    Description: 'Description',
                    Total: 'Total',
                    'Give this virtual gift': 'Offrir ce cadeau virtuel',
                    'Put on hold': 'Mettre en attente',
                    From: 'De :',
                    To: 'À :',
                    Attachment: 'Photo jointe :',
                    'Choose file': 'Choisir un fichier',
                    'Expose you nick to': 'Affichez votre pseudo à :',
                    'PM gift': 'Message privé (optionnel)',
                    'By giving this virtual gift':
                        'En offrant ce cadeau virtuel, votre porte-monnaie virtuel sera débité du nombre de jetons correspondant à la valeur du cadeau.',
                    'You can attach a message':
                        'Vous pouvez joindre un message et/ou une photo à ce cadeau virtuel. Ce message et cette photo resteront confidentiels, seul le destinataire en aura connaissance.',
                    'Your virtual gift':
                        'Votre cadeau virtuel (sans votre message et votre photo) sera affiché sur la page profil du destinataire.',
                    'You can choose between 3 options': 'Vous pouvez choisir entre 3 options :',
                    'Show your nickname to': '- Affichez votre pseudo à : Tous',
                    'Your nickname will be visible':
                        'Votre pseudo sera visible par tous les utilisateurs sur la page profil du destinataire.',
                    'Show your nickname to recipient': '- Affichez votre pseudo à : Seulement au destinataire',
                    'Your nickname will be visible only to the recipient':
                        'Votre pseudo sera visible uniquement par le destinataire.',
                    'Show your nickname to nobody': '- Affichez votre pseudo à : Personne',
                    'Your nickname will not be visible':
                        'Votre pseudo ne sera pas visible par le destinataire, ni par les autres utilisateurs.',
                    'Your virtual gift will be anonymous': 'Votre cadeau virtuel sera anonyme.',
                    'Give a virtual gift': 'Offrir un cadeau virtuel',
                    'Back to profile of': 'Retour au profil de ',
                    'View message': 'Lire le message',
                    'You can hide your virtual gifts':
                        'Vous pouvez cacher vos cadeaux virtuels, ils ne seront plus affichés sur votre profil.',

                    // notifications
                    Notifications: 'Notifications',
                    Settings: 'Paramètres',
                    'Notification services': 'Liste des notifications',
                    notificationsDescription:
                        'Dans les paramètres, sélectionnez les notifications que vous souhaitez recevoir sur le site et par émail.',
                    'Notification List': 'Historique de mes notifications :',
                    'Notification settings': 'Paramètres des notifications',
                    'You will receive notifications at': 'Vos notifications sont envoyées sur',
                    follower_notification: 'Quand un membre me suit:',
                    guestbook_notification: "Quand un message est posté sur mon livre d'or:",
                    contribution_notification: 'Quand un de mes abonnements poste une contribution:',
                    gift_notification: "Quand on m'offre un cadeau virtuel ou un abonnement Premium :",

                    'Notify me on site': 'me notifier sur le site.',
                    'Notify me by email': 'me notifier par émail.',
                    'upload a new': 'a publié une nouvelle',
                    'sent you a': "m'a adressé un",
                    // 'starts following you': 'me suit maintenant',
                    message: 'message',
                    'guestbook message': "message sur mon livre d'or",
                    contribution: 'contribution',
                    'New message from': 'Nouveau message de ',
                    'New guestbook message': "Nouveau message de <1>{{userNick}}</1> sur votre livre d'or.",
                    'sent you a gift': ' vous a offert un cadeau virtuel.',
                    'sent you a gift notification': ' <0>vous a offert un </0><1>cadeau virtuel.</1>',
                    'starts following you': ' commence à vous suivre.',
                    'added a new contribution': '  a publié une nouvelle contribution.',
                    'Premium gifted': "<0>{{userNick}}</0> vous a offert {{amount}} jours d'abonnement Premium.",
                    'Premium gifted list': "<0>vous a offert </0><1>{{amount}} jours d'abonnement Premium.</1>",
                    'Premium ends':
                        'Votre abonnement Premium expire dans {{amount}} jours. <3>Renouvelez le maintenant</3> pour conserver votre accès au contenu Premium.',
                    'Premium ended':
                        'Votre abonnement Premium est expiré. <1>Renouvelez le maintenant</1> pour conserver votre accès au contenu Premium.',
                    'Close all notifications': 'Fermer toutes les notifications ({{amount}})',
                    'Notification settings changed': 'Paramètres des notifications modifiés.',

                    // labels popup
                    'Enter your labels, separated by commas': 'Entrez vos mots-clés:',
                    'Added labels': 'Mots-clés ajoutés. Cliquez sur un mot-clé pour le supprimer:',
                    'You dont add any labels yet': "Vous n'avez pas encore ajouté de mot-clé",
                    'Participate in the labelling of photos and videos by writing a few words to describe them':
                        'Participez au classement des photos et des vidéos en attribuant des mot-clés pour les décrire',
                    'Suggest labels:': 'Suggérer des mots-clés:',
                    'Directly related to the content of the photo or video':
                        'en rapport direct avec le contenu de la photo ou de la vidéo.',
                    'Only for the photos and videos of very good quality':
                        'uniquement pour les photos et les vidéos de trés bonne qualité.',
                    'Only for photos in which the faces are not hidden':
                        'uniquement pour les photos sur lesquelles les visages ne sont pas masqués.',
                    'Thank you for labeling this photo': "Merci d'avoir décrit cette photo!",
                    'Thank you for labeling this video': "Merci d'avoir décrit cette vidéo!",
                    'Add labels': 'Soumettre mes mots-clés',
                    'No labels': "Il n'y a pas de mots-clés pour décrire ce contenu. Ajoutez en!",
                    'Label this photo': 'Attribuer des mots-clés à cette photo',
                    'Label this video': 'Attribuer des mots-clés à cette vidéo',
                    'You must be a Premium member to label this photo':
                        'Vous devez être membre Premium pour attribuer des mots-clés à cette contribution.',

                    // popups
                    'Invalid email': 'Cette adresse émail n’est pas valide.',
                    'Email is required': 'Une adresse émail est requise',
                    'Emails must match': 'Les adresses émails doivent correspondre',
                    'Email confirmation is required': 'Une adresse émail de confirmation est requise',
                    'New email address': 'Nouvelle adresse émail',
                    'New email address again': 'Confirmez la nouvelle adresse émail',
                    'Validate email': "Validater l'adresse émail",
                    'Validation code': 'Code de validation',
                    Send: 'Envoyer',
                    'Change nick': 'Changer de pseudo',
                    'New nick': 'Nouveau pseudo',
                    nickRules: ' Votre demande sera rejetée dans les cas suivants :',
                    disrespect: '- pseudo vulgaire ou irrespectueux.',
                    referring: '- allusion à une marque déposée ou à une personnalité.',
                    capital: '- pseudo en lettres majuscules.',
                    exists: '- le pseudo existe déjà.',
                    'Passwords must match': 'Les mots de passe doivent correspondre.',
                    'Password confirmation is required': 'Vous devez confirmer le mot de passe.',
                    'Password must be at least 4 characters': 'Le mot de passe doit comporter au moins 4 caractères.',
                    'Password must be at least 6 characters': 'Le mot de passe doit comporter au moins 6 caractères',
                    'Password is too common': "Le mot de passe n'est pas assez complexe.",
                    'Same as nick': 'Identique au pseudo.',
                    "Password can't be same as nick": 'Le mot de passe ne peut pas être identique au pseudo.',
                    'Minimum 3 characters': '3 caractères au minimum.',
                    'Maximum 100 characters': '100 caractères au maximum.',
                    'Email or nick is required': "L'émail ou le pseudo est nécessaire.",
                    'Email or nick': 'Email ou pseudo',
                    'Minimum 4 digits': '4 chiffres au minimum.',
                    'Maximum 4 digits': '4 chiffres au maximum.',
                    'Validation token is required': 'Le jeton de validation est nécessaire.',
                    'should be at list 2 symbols': 'doit être au minimum de 2 symboles.',
                    'You need to be authorized to use this feature': 'Connectez-vous pour utiliser cette fonction.',
                    'You need to become Premium member to use this feature':
                        'Vous devez être membre Premium pour utiliser cette fonction.',
                    'Become a Premium member': 'Devenez membre Premium',

                    //album
                    'My album': 'Mon album',
                    'All my photos': 'Toutes mes photos',
                    'You don&apos;t have any photos': "Vous n'avez publié de photo dans vos albums",
                    'But you always can upload some': 'mais vous pouvez en ajouter à tout moment.',
                    'doesn&apos;t have any photos': "n'a pas de photo",
                    'Upload photo': 'Ajouter une photo',
                    'Upload photos': 'Ajouter des photos',

                    //contributions
                    'Become a member': 'Vous devez être membre Premium',
                    'to watch more': 'pour visionner cette vidéo.',
                    'Login if you are already a member': 'Connectez-vous si vous êtes déjà membre',
                    'Please log in to view this photo': 'Veuillez vous connecter pour voir cette photo.',
                    'Become a member to view this photo': 'Devenez membre pour voir cette photo.',
                    'Become a member to watch the rest of the video':
                        'Devenir membre pour regarder la vidéo en entier...',
                    'Please log in to watch the rest of the video':
                        'Veuillez vous connecter pour regarder la vidéo en entier...',
                    'My contributions': 'Mes contributions',
                    'You don&apos;t have any contributions': "Vous n'avez pas publié de contribution",
                    ' doesn&apos;t have any contributions': " n'a aucune contribution",
                    'All my contributions': 'Toutes mes contributions',
                    'Upload contributions': 'Publiez vos photos et vidéos',
                    'Add contribution to favourites': 'Ajouter à mes contributions favorites',
                    'My favorites': 'Mes favoris',
                    'My favorite': 'Mes favoris',
                    'Enter your labels': 'Ajoutez vos mots-clés.',
                    'You can add only 3 labels': 'Vous ne pouvez ajouter que 3 mots-clés.',
                    'Suggest labels': 'Suggérez des mots-clés',
                    'of 12': 'de 12',
                    'submitted on': 'publiée le ',
                    Favorite: 'Favori',

                    //swiper
                    'to open this photo': 'pour visionner cette photo.',
                    'You must be a member': 'Vous devez être membre Premium',
                    'Become an XXL member': 'Devenez membre Premium ',

                    //disclaimer
                    'Site fo adults only!': 'Site exclusivement reserve aux adultes!',
                    'According to the French law':
                        'Selon la Loi française, la majorité est fixée à 18 ans accomplis – Article 488 al. 1 du Code civil',
                    'This website is reserved for a major and warned public':
                        'Ce site Internet est réservé à un public majeur et averti',
                    'It contains texts, pornographic photos and videos prohibited to minors and which may be offensive to some sensibilities':
                        'Il contient des textes, des photos et des vidéos pornographiques interdits aux mineurs, qui peuvent être choquants pour certaines sensibilités',
                    'It is strongly discouraged to sensitive, fragile and impressionable people to access this site; minors mustnt have access under any circumstances':
                        'Il est vivement déconseillé aux personnes sensibles, fragiles et impressionnables d’accéder à ce site ; les mineurs ne doivent en aucun cas y accéder',
                    'I validate the data contained in the following paragraphs after its careful reading, and I agree fully and completely, certifying to fulfill all mentioned conditions, by checking the checkboxprovided':
                        'Je valide les données contenues dans les paragraphes ci-dessous après les avoir lues attentivement et manifeste ainsi mon accord plein et entier, en certifiant remplir toutes les conditions qui y sont contenues, en cochant les cases prévues à cet effet',
                    'I certify to': 'Je certifie sur l’honneur',
                    'Be major according to the Act of my country, and to the French law':
                        'Etre majeur selon la Loi en vigueur dans mon pays et selon la Loi française',
                    'That the laws of my state or my country authorize me to access this site and that bobtvfr has the right to provide me with such data':
                        'Que les Lois de mon état ou de mon pays m’autorisent à accéder à ce site et que bobvoyeur.com a le droit de me transmettre de telles données',
                    'Being informed of pornographic character of content on the server':
                        'Etre informé du caractère pornographique du serveur auquel j’accède',
                    'I declare and acknowledge explicitly': 'Je déclare et reconnais expressément',
                    'Not to be shocked by any type of sexuality, any text, any pornographic pictures or video, and I prohibit myself to prosecute the authors of bobtvfr due to the data on this site or its consequences':
                        'N’être choqué par aucun type de sexualité et par aucun texte, ni aucune image ou vidéo à caractère pornographique, et je m’interdis expressément de poursuivre les auteurs de bobvoyeur.com à raison des données diffusées sur ce site ou de leurs conséquences ',
                    'To consult this server only for personal needs and without any involvement of a private company, a government agency, or any other public or private entity':
                        'Consulter ce serveur à titre strictement personnel, et sans aucune implication, de quelque manière que ce soit, d’une société privée, d’un organisme public, ou de toute autre entité publique ou privée ',
                    'I pledge on my honor': 'Je m’engage sur l’honneur',
                    'never announce the existence of the server to minors':
                        'A ne jamais faire état de l’existence de ce serveur auprès de tiers et de mineurs',
                    'never allow minors to access this server and use all conducive means to prevent access this server by minors':
                        'A ne jamais permettre à des mineurs d’accéder à ce serveur et à mettre en œuvre tous moyens de nature à permettre d’empêcher l’accès de tout mineur à ce serveur',
                    'never broadcast all or a part of the content published on this server to minors':
                        'A ne jamais diffuser tout ou partie du contenu édité sur ce serveur à des mineurs pour quelque raison que ce soit',
                    'to assume my responsibility in case that a minor would have access to the server because of my negligence or my recklessness (lack of protection of my computer, no censorship software, loss or disclosure of the password security ), without recourse against bobtvfr':
                        'A assumer ma responsabilité dans l’hypothèse où un mineur aurait eu accès à ce serveur à raison de ma négligence ou de mon imprudence (absence de protection de mon ordinateur, absence de logiciel de censure, divulgation ou perte du mot de passe de sécurité), sans recours contre bobvoyeur.com et ses auteurs et en garantissant d’ores et déjà bobvoyeur.com et ses auteurs à ce titre',
                    'to fully assume all the consequences of all kinds of declarations at all or partyally inaccurate, without recourse against its authors and bobtvfr':
                        'A assumer sans réserves toutes les conséquences de toutes natures à raison de déclarations en tout ou partie inexactes, sans recours contre bobvoyeur.com et ses auteurs',
                    'I confirm that I have read and accepted without reservation all the provisions contained in the Terms and Conditions of this server':
                        'Je certifie avoir lu et accepté sans réserves l’ensemble des dispositions contenues dans les ' +
                        '<1>Conditions Générales d’Utilisation</1> de ce serveur',
                    'You must agree to the Terms and Conditions of Use for accessing this server':
                        "Vous devez accepter les Conditions Générales d'Utilisation pour accéder à ce serveur.",

                    //search and tags

                    'Search photo and video by tags': 'Cherchez des photos et des vidéos par mots-clés',
                    'First line of description':
                        "Les photos et les vidéos d'amateurs de Bob Voyeur sont progressivement et grâce à vous, classées par mots-clés. ",
                    'Full description': `Le contenu amateur de Bob Voyeur est progressivement et grâce à vous, classées par mots-clés.
                    Participez à ce projet en attribuant des mots-clés aux photos et aux vidéos que vous regardez quotidiennement. 
                    Un formulaire d'ajout de mots-clés est disponible sous chaque photo et sous chaque vidéo.
                    Ce moteur de recherche vous permet de trouver des photos et des vidéos qui correspondent à vos goûts et à vos envies. `,
                    'Show less': 'Cacher',
                    'Show more': 'Lire plus',
                    All: 'Tous',
                    Tout: 'Tout',
                    Video: 'Vidéo',
                    Materials: 'Médias',
                    'Search results': 'résultats',
                    'What are you looking for': `Que cherchez vous ?`,
                    'View all tags': 'Voir tous les mots-clés',
                    'View all searches': 'Voir toutes les recherches',
                    'Tag list': 'Liste des mots-clés',
                    'The most popular searches': 'Les recherches les plus fréquentes',
                    'Search result': '(1)[résultat];(3)[résultats]',

                    //contact
                    'Contact info': `Utilisez ce formulaire pour toute demande concernant votre compte d'utilisateur sur Bob Voyeur.
                                 Indiquez une adresse émail valide pour recevoir une réponse dans les plus brefs délais.`,
                    'Your message has been sent': 'Votre message a été envoyé',
                    Message: 'Message',
                    Status: 'Statut :',
                    Free: 'Gratuit',
                    Ok: 'Ok',
                    dots: ' : ',

                    //Submit contribution

                    'Submit your contribution': 'Publiez votre contribution',
                    'Upload your photos and video': 'Téléchargez vos photos et vos vidéos personnelles',
                    'Drag your files here': 'Glissez vos fichiers ici...',
                    'Add files': 'Ajoutez des fichiers',
                    'Or add files from': 'ou ajoutez les à partir de ...',
                    'Photos in jpg format with minimum size':
                        'Vos photos doivent être au format .jpg uniquement et de taille 1280x960 minimum.',
                    'Do not upload compressed files': "N'envoyez pas de fichiers compressés (.zip, .rar, ...)",
                    'Video in format':
                        'Les videos doivent être au format .mpeg .wmv .mov ou .avi et ne pas dépasser 500 Mo.',
                    'Low quality photos will not be published':
                        'Seules les photos de bonne qualité seront publiées sur le site.',
                    'Your comment': 'Rédigez un commentaire ...',
                    'Name and describe your contribution':
                        'Rédigez un titre et une description pour votre contribution',
                    'Title of your contribution': 'Titre de votre contribution',
                    'Submit contribution first line description': `Un texte de description est toujours très apprécié par les visiteurs, ce texte nous aide à mieux vous connaître. Prenez le temps de décrire votre contribution, chacune de vos photos et chacune de vos vidéos.`,
                    'Submit contribution second line description': `Vos textes sont automatiquement traduits en plusieurs langues, soignez votre orthographe et la ponctuation pour être lu et compris de tous.`,
                    'Contribution description': 'Description de votre contribution',
                    'Blur my face': 'Flouter les visages sur toutes mes photos.',
                    'I confirm that I am owner of these submitted files':
                        'Je certifie être propriétaire de ces fichiers et que les personnes exposées sont majeures et consentantes.',
                    'I authorize the publication of these files':
                        "J'autorise la publication de ces fichiers sur le site bobvoyeur.com",
                    'Desired section': 'Choisissez une section pour publier votre contribution :',
                    'Pending moderation': 'En attente de modération',
                    'Waiting for uploading': 'En attente de téléchargement',
                    'Please confirm that you authorize publication of these files on':
                        'Vous devez confirmer que vous autorisez la publication de ces fichiers sur le site bobvoyeur.com',
                    'You need confirm that you are owner and the models on your materials are adult':
                        'Vous devez confirmer que vous êtes le propriétaire de ces fichiers et que les personnes exposées sont majeures et consentantes.',
                    'Minimum 4 attachments in contribution': 'Vous devez poster 4 photos au minimum.',
                    'Maximum 12 attachments contribution': 'Vous pouvez poster 12 photos au maximum.',
                    'Title is required': 'Un titre est nécessaire.',
                    'Conditions of receiving free access to Premium': 'Recevez un abonnement Premium gratuit :',
                    '15 days free for participating in Hot section (section H)':
                        "15 jours d'abonnement en publiant des photos d'actes sexuels (section H).",
                    '15 days free for sending one video over 30 seconds long (section V)':
                        "15 jours d'abonnement en publiant une vidéo de plus de 30 secondes (section V).",
                    '30 days free for attaching a certified photo to your contribution':
                        "30 jours d'abonnement en joignant une photo certifiée à votre contribution.",
                    'Only certified amateurs can receive free access to Premium':
                        'IMPORTANT : Seuls les amateurs certifiés peuvent recevoir un abonnement Premium gratuit.',
                    maxNumberOfFiles: 'Vous pouvez poster 12 photos ou vidéos maximum.',
                    acceptFileTypes:
                        'Seules les photos au format .jpg, .jpeg et les vidéos au format .mp4, .mpeg, .wmv, .mov ou .avi sont acceptées.',
                    maxFileSize: 'Le fichier doit faire moins de 500 Mo.',
                    minMaterialSize:
                        'La photo est trop petite. Elle doit faire au moins 1280 pixels en largeur ou en hauteur.',

                    // upload photo popup

                    'Add a photo': 'Ajoutez une photo',
                    'Drag your file here': 'Glissez votre fichier ici ...',
                    'Or add from': 'ou ajoutez le à partir de ...',
                    Add: 'Ajouter',
                    'Upload in': "Envoyer dans l'album:",
                    'Photo description': 'Description de la photo ...',
                    'The uploaded image size should be at least':
                        'Les photos doivent être de taille 1024x768 au minimum.',
                    Private: 'Privé',
                    Public: 'Public',
                    'Edit photo': 'Modifier une photo',
                    Album: 'Album:',

                    // stub

                    'Authorization required': 'Autorisation requise',
                    'Only registered users have access to this page':
                        'Seuls les utilisateurs enregistrés ont accès à cette page',
                    // Please: 'Veuillez vous',
                    // login: 'connecter',
                    // 'to open this page': 'pour ouvrir cette page',
                    'Please login to open this page': 'Veuillez vous <1>connecter</1> pour ouvrir cette page',

                    //pm

                    recipient_busy: 'Le destinataire est occupé.',
                    unanswered: "Le destinataire n'a pas répondu à l'appel vidéo.",
                    declined: "Le destinataire a refusé de répondre à l'appel vidéo.",
                    notconnected: "Problème de connexion. Impossible de démarrer l'appel vidéo.",
                    finished: "L'appel vidéo est terminé.",
                    client_logout: 'Le correspondant a quitté la conversation.',
                    'no chats':
                        "Vous n'avez pas de message privé. Vous pouvez débuter une conversation en ajoutant un utilisateur via le formulaire sur cette page.",
                    'Waiting for loading chats': 'En attente de chargement des messages privés',
                    'Delete history': "Effacer l'historique",
                    'Revoke permission': "Révoquer l'autorisation",
                    'Video call': 'Appel vidéo',
                    Archive: 'Archive',

                    //Premium history
                    'Your Premium subscription is active till': "Votre abonnement Premium est valide jusqu'au ",
                    'Purchase date': "Date d'achat",
                    'Ticket number': 'Numéro de ticket',
                    Type: 'Type',
                    Purpose: 'Objet',
                    'Given by': 'Offert par <1>{{userNick}}</1>',
                    'given by': ' offert par ',
                    'Gift for': 'Cadeau pour <1>{{userNick}}</1>',
                    deleted: 'supprimé',
                    'Give Premium': 'Offrir un Premium',

                    //gifts
                    'Give a virtual gift to': 'Offrez un cadeau virtuel à ',
                    'You dont have any gifts': "Vous n'avez pas de cadeau",
                    'doesnt have any gifts': "n'a pas de cadeau",
                    'Recipient only': 'Seulement au destinataire',
                    Nobody: 'Personne',
                    'Do you really want to delete this message': 'Voulez-vous vraiment supprimer ce message?',
                    'an anonymous': 'un anonyme',

                    // my favorites
                    'You have no favorite contributions': "Vous n'avez pas de contributions favorites",
                    'You have no favorite contributors': "Vous n'avez pas de contributeurs favoris",
                    Delete: 'Supprimer',

                    // video call
                    'Incoming video call': 'Appel vidéo entrant',
                    Answer: 'Répondre',
                    Decline: 'Décliner',
                    'Video call to': 'Appel vidéo à '
                }
            },
            en: {
                translation: {
                    // common
                    Nick: 'Nick',
                    Sex: 'Sex',
                    Male: 'Male',
                    male: 'Male',
                    Female: 'Female',
                    female: 'Female',
                    Transvestite: 'Transvestite',
                    'Female or couple': 'Female or couple',
                    'Females or couples': 'Females or couples',
                    'Only photo': 'Only photo',
                    'Only video': 'Only video',
                    'Females and males': 'Females and males',
                    'Males only': 'Males only',
                    Couple: 'Couple',
                    couple: 'Couple',
                    years: 'years',
                    User: 'User',
                    Location: 'Location',
                    Cancel: 'Cancel',
                    'Clear filters': 'Clear Filters',
                    'All content': 'All content',
                    'Search content': 'Search content',
                    Search: 'Search',
                    Activate: 'Activate',
                    'Log in': 'Log in',
                    'Sign up': 'Sign up',
                    'Password reset': 'Password reset',
                    Material: 'Material',
                    Period: 'Period',
                    Contributors: 'Contributors',
                    'Last year': 'Last year',
                    'Last week': 'Last week',
                    'Last month': 'Last month',
                    'All time': 'All time',
                    Disable: 'Disable',
                    Expiration: 'Expiration: ',
                    Users: 'Users',
                    Select: 'Select',
                    'No options': 'No options',
                    'The page you were looking for was not found': 'The page you were looking for was not found',

                    // auth forms
                    Enter: 'Enter',
                    Exit: 'Exit',
                    loginError:
                        'Failed to log in. Please make sure that you have entered your login and password correctly.',
                    Password: 'Password',
                    forgotPassword: 'Forgot password?',
                    'Nick is required': 'Password is required',
                    'Password is required': 'Password is required',
                    'Password confirmation': 'Password confirmation',
                    'Not specified': 'Not specified',
                    'Photo and Video': 'Photo and Video',
                    Photo: 'Photo',
                    forgotPasswordHelpText: 'Enter your email address or nick to receive your password immediately',
                    'Current password': 'Current password',
                    'New password': 'New password',
                    'New password again': 'New password again',
                    'Activate your account to view this photo': 'Activate your account to view this photo',
                    'Activate your account to watch the rest of the video':
                        'Activate your account to watch the rest of the video',
                    'You need to activate your account to use this feature':
                        'You need to activate your account to use this feature',

                    // menu
                    Menu: 'Menu',
                    Home: 'Home',
                    Forum: 'Forum',
                    Wiki: 'Wiki',
                    Links: 'Links',
                    CGU: 'CGU',
                    Contact: 'Contact Bob',
                    Information: 'Information',
                    'Webcamchat on BobTV': 'Webcam Chat of bobTV',
                    'Les sites de Bob': "Bob's websites",
                    'My membership Premium': 'My membership Premium',
                    'Single period, without automatic rebilling': 'Single period, without automatic rebilling',
                    'Immediate access': 'Immediate access',
                    'Access Premium features': 'Access Premium features',
                    'My virtual gifts': 'My virtual gifts',
                    'Buy more Premium': 'Buy more Premium',
                    activationFormText:
                        'Please enter the activation code we sent to your email ' +
                        `<1>{{email}}</1> or click the activation link from email message. If you do not receive this email immediately, check your 'spam' folder and mark this as 'non-spam'.`,
                    Reload: 'Reload',
                    'Oops, something went wrong': 'Oops, something went wrong',
                    'Try to reload the page': 'Try to reload the page',
                    'Delete my account': 'Delete my account',
                    'You can request deletion':
                        'You can request deletion of your account <span>{{nick}}</span> by clicking the button below.',
                    'All data in this account will be deleted':
                        'All data in this account will be deleted as soon as possible by the administrator (subscription, photos, videos, email addresses ...)',
                    'Your request will not be cancelable':
                        'WARNING: Your request will not be cancelable, it will not be possible to access your account, your data and your subscription after its removal.',

                    //payment
                    'Buy Premium Membership': 'Buy Premium Membership',
                    'Give Premium Membership': 'Give Premium Membership',
                    'Membership history': 'Membership history',
                    'Choose your Premium plan': 'Choose your Premium plan',
                    "Choose what Premium plan you'll give to": "Choose what Premium plan you'll give to",
                    'More information about Functions Premium': 'More information about Premium Functions',
                    Functions: 'Functions',
                    'Unlimited simultaneous displaying of 100 Webcams':
                        'Unlimited simultaneous displaying of 100 Webcams',
                    contributionsAndMaterialsCount:
                        'to view all the archives <1>{{contributionsCount}}</1> contributions, <3>{{materialCount}}</3> videos and photos of real amateurs',
                    'to view the contributions of Hot section, Premium themes':
                        'to view the contributions of Hot section, Premium themes',
                    'to view all videos of long duration': 'to view all videos of long duration',
                    'to download and save videos of original quality':
                        'to download and save videos of original quality',
                    'to view full size photos with greater size 1280x960':
                        'to view full size photos with greater size 1280x960',
                    'to upload 40 photos to your profile': 'to upload 40 photos to your profile',
                    'to view photos in Premium albums of all members':
                        'to view photos in Premium albums of all members',
                    'Secure server, payment page is encrypted with 128 Bit SSL':
                        'Secure server, payment page is encrypted with 128 Bit SSL',
                    'Card number': 'Card number',
                    'Expiration date': 'Expiration date',
                    'Security code': 'Security code',
                    'Enter security code': 'Enter security code',
                    'This code consists of the last 3 digits at the back side of most credit cards':
                        'This code consists of the last 3 digits at the back side of most credit cards',
                    'Your email address': 'Your email address',
                    'Payment first line':
                        'Your payment confirmation will be sent to this email address. Be sure to specify a valid address ',
                    'Payment second line':
                        'to receive this message. Check your SPAM folder if you do not receive this message in your ',
                    inbox: 'inbox',
                    Confirm: 'Confirm',
                    'Credit card number is required': 'Credit card number is required',
                    'You can attach a message for the recipient, if you want':
                        'You can attach a message for the recipient, if you want',
                    'Replenish your wallet': 'Replenish your wallet',
                    Membership: 'Membership',
                    singlePaymentText:
                        '<0>Single payment, without automated renewal.</0> You will be charged <2>only</2> once.',
                    'Enter a card number': 'Enter a card number',
                    'MM/YY': 'MM/YY',
                    'Card number is invalid': 'Card number is invalid',
                    'Expiry month must be between 01 and 12': 'Enter an expiry date',
                    'Expiry year cannot be in the past': 'Expiry month must be between 01 and 12',
                    'Expiry date cannot be in the past': 'Expiry year cannot be in the past',
                    'Expiry date is invalid': 'Expiry date cannot be in the past',
                    'Enter a CVC': 'Expiry date is invalid',
                    'CVC is invalid': 'CVC is invalid',

                    // index
                    'Index title': 'Bob Voyeur is a site \n dedicated to exhibitionism and voyeurism',
                    filters: 'filters',
                    Filters: 'Filters',
                    'Apply filters': 'Apply filters',
                    'Certified and non-certified': 'Certified and non-certified',
                    'Only Free': 'Only Free',
                    'Only Certified': 'Only Certified',
                    'Amateur contributions': 'Amateur contributions',
                    'Videochat rooms to exhibit on webcam for amateur libertines and voyeurs':
                        'Videochat rooms to exhibit on webcam for amateur libertines and voyeurs',
                    'Nudity and sex webcams are accepted on chat rooms':
                        ' Nudity and sex webcams are accepted on chat rooms',
                    'Load more': 'Load more',
                    Online: 'Online',
                    Offline: 'Offline',
                    'Certified info': `Several hundred amateurs and amateur adult couples have already certified in the Bob Voyeur.
If you want to be certified as amateur, simply attach to your submission the photo showing that you are a true amateur couple or a true amateur fan of Bob Voyeur site.
This photo must have a female model and the name of our favorite site. You can write "Bob Voyeur" on a sheet of paper, on your skin or on a computer screen ... Be creative ;-)`,
                    'Best info': `The Best Of section is a very personal Bob’s selection among all private photos of Bob Voyeur fans. I, Bob, choose the best hot pictures from the whole material sent me by adult men, hot skilful women and amateur real couples. The collection of best erotic and porn photos represents the spirit of beauty, love and sex that is important for Bob Voyeur. The photos are carefully selected for the eroticism they emit, the image quality and the choice of original background.`,
                    Locate: 'Localiser',
                    'Certified females and couples': 'Certified females and couples',
                    'The Best submitted photos': 'The Best submitted photos',
                    'Female and couple photos': 'Female and couple photos',
                    'Female and couple videos': 'Female and couple videos',
                    'Male photos and videos': 'Male photos and videos',
                    'The best photos': 'The best photos',

                    // profiles search
                    Profiles: 'Profiles',
                    Profiles_page: 'Profiles',
                    'Profiles info':
                        'The page bobvoyeur.com members allows you to search all the profiles registered on site.',
                    'With album photo': 'With album photo',
                    'Certified amateurs': 'Certified amateurs',
                    'With contributions': 'With contributions',
                    'Online within 10 days': 'Online within 10 days',
                    'User nick': 'User nick',
                    Gender: 'Gender',
                    Age: 'Age',
                    Country: 'Country',
                    Region: 'Region',
                    'Show more filters': 'Show more filters',
                    Profile: 'Profile',

                    // profile
                    'Last online': 'Last online',
                    'time format': 'DD/MM/YYYY [at] HH:mm',
                    views: 'views',
                    followers: 'followers',
                    block: 'block',
                    unblock: 'unblock',
                    'Public album': 'Public album',
                    'All photos': 'All photos',
                    Contributions: 'Contributions',
                    'All contributions': 'All contributions',
                    Guestbook: 'Guestbook',
                    'Add a message': 'Add a message',
                    About: 'About',
                    Change: 'Change',
                    'Change email': 'Change email',
                    'Change password': 'Change password',
                    'Allow private messages': 'Allow private messages',
                    'Tell something about you': 'Tell something about you',
                    Save: 'Save',
                    Reset: 'Reset',
                    "amateur sur Bob Voyeur le site libertin de l'exhib et de l'échangisme":
                        "amateur sur Bob Voyeur le site libertin de l'exhib et de l'échangisme",
                    'All of my guestbook': 'All of my guestbook',
                    'All guestbook messages': 'All guestbook messages',
                    'Current email address': 'Current email address:',
                    'Photos of your public album can be viewed by all registered visitors':
                        'Photos of your public album can be viewed by all registered visitors.',
                    'Photos of your Premium album can be viewed by Premium members only':
                        'Photos of your Premium album can be viewed by Premium members only.',
                    'Your private album is visible only to the members of your friends lis':
                        'Your private album is visible only to the members of your friends list.',
                    'A text description':
                        'A text description for each of your photos is always appreciated by the visitors, this text helps us to know you better. Please describe the content of your photos with a few words.',
                    'of 20': ' of 20',
                    'of 40': ' of 40',
                    public: 'public',
                    private: 'private',
                    Give: 'Give',
                    'My albums': 'My albums',
                    'Private album': 'Private album',
                    'Premium album': 'Premium album',
                    'All virtual gifts': 'All virtual gifts',
                    'All  my virtual gifts': 'All my virtual gifts',
                    'Participations in the forum': 'Participations in the forum',
                    'My participations in the forum': 'My participations in the forum',
                    'My participations in the forum button': 'My participations in the forum',
                    'I have not yet participated in the forum':
                        "I have not yet participated in the <a href='/forum/'>forum</a>.",

                    //geo
                    'Around me': 'Around me',
                    'Search radius (km)': 'Search radius (km):',
                    'Move the cursor to display my position with more or less precision':
                        'Move the cursor to display my position with more or less precision:',
                    'Share my position on the map': 'Share my position on the map',
                    'My mood': 'My mood:',
                    'Sort by': 'Sort by:',
                    'Near to me': 'Near to me',
                    'Change your humor': 'Change your humor',
                    Humor: 'Humor...',
                    'Users in table': '(1)[user];(3)[users]',
                    map_distance: 'within {{count}}m.',
                    'exact position': 'exact position',
                    'approximate position': 'approximate position',
                    'Login to estimate distances': 'Login to estimate distances.',
                    'Share your position on the map to estimate distances':
                        'Share your position on the map to estimate distances.',
                    'Enable geolocation in your browser settings to estimate distances':
                        'Enable geolocation in your browser settings to estimate distances',
                    'Higher precision only available on mobile devices':
                        'Higher precision only available on mobile devices',
                    'geo time format': 'DD/MM [at] HH:mm',
                    'Exact position on': 'Exact position on',
                    'Approximate position on': 'Approximate position on',
                    exactly: 'exactly',
                    approximately: 'approximately',
                    'Your location': 'Your location',
                    //FAQ geo
                    'Authorize the bobvoyeur-com site to access your location in your browser':
                        'Authorize the bobvoyeur.com site to access your location in your browser.',
                    'Share your position on the map to estimate the distances between you and other members':
                        'Share your position on the map to estimate the distances between you and other members.',
                    ThirdLineFAQGeo:
                        "Move the precision slider to display your position with greater or less precision. If you're using your phone, you can share your exact location using your device's GPS. If you are using a computer, your location on the map will be approximate.",
                    'Write a short mood text to describe your interests':
                        'Write a short mood text to describe your interests.',
                    FivesLineFAQGeo:
                        'The map displays members who are online or who have connected to this service in the last 7 days. Their position is exact or approximate depending on their choice of precision.',

                    // blacklist
                    'My blacklist': 'My blacklist',
                    'My blocked users': 'My blocked users',
                    'Blacklist description':
                        'If you add a nick to your black list, that user will no longer send you any message on site.',
                    Duration: 'Duration',
                    Reason: 'Reason',
                    Block: 'Block',
                    Unblock: 'Unblock',
                    'Are you sure?': 'Are you sure?',
                    'Do you want to unblock': 'Do you want to unblock',
                    'Describe your reason': 'Describe your reason',
                    'For how long?': 'For how long?',
                    '24 hours': '24 hours',
                    Permanent: 'Permanent',
                    'Add to blacklist': 'Add to blacklist',
                    'Why would you add to your black list': 'Why would you add to your black list',
                    'There are no users in your blacklist': 'There are no users in your blacklist',
                    'List of blocked users': 'List of blocked users',
                    'Adding date': 'Adding date',
                    'Time of blocking': 'Time of blocking',
                    'Invalid credit card number': 'Invalid credit card number',

                    // following
                    follow: 'Follow',
                    unfollow: 'Unfollow',
                    Following: 'Following',
                    Followers: 'Followers',
                    'Follow user': 'Follow user',
                    'Search by name': 'Search by name',
                    'My followers': 'My followers',
                    'My followees': 'My followees',
                    'Members I follow': 'Members I follow',
                    'have no followers yet': ' have no followers yet',
                    'have no followed users': ' have no followed users',
                    'You have no followers yet': 'You have no followers yet',
                    'There are no followed users': 'There is no followed users',

                    // messages
                    'My messages': 'My messages',
                    'Send a message': 'Send a message',
                    Messages: 'Messages',
                    'My requests': 'My requests',
                    'Private messages': 'Private messages',
                    'New message': 'New message',

                    // guestbook
                    'My guestbook': 'My guestbook',
                    'You dont have any guestbook messages': "You don't have any guestbook messages",
                    ' doesnt have any guestbook messages': " doesn't have any guestbook messages",
                    'Disable my Guestbook': 'Disable my Guestbook',
                    'Add message to Guestbook of': 'Add message to Guestbook of',

                    // wallet
                    'My wallet': 'My wallet',
                    'Virtual gifts': 'Virtual gifts',
                    'Pending gifts': 'Pending gifts',
                    History: 'History',
                    Replenish: 'Replenish',
                    points: 'tokens',
                    tokens: 'tokens',
                    days: 'days',
                    'Your virtual wallet contains points': 'Your virtual wallet contains tokens.',
                    'You can purchase these points by crediting your virtual wallet by a secure online payment':
                        'You can purchase these tokens by crediting your virtual wallet by a secure online payment.',
                    'The points accumulated in your virtual wallet have unlimited validity':
                        'The tokens accumulated in your virtual wallet have unlimited validity.',
                    'You can use these points to offer virtual gifts to any bobVoyeur user by your choice':
                        'You can use these tokens to offer virtual gifts to any bobVoyeur user by your choice.',
                    "You can buy points and give virtual gifts even if you're not Premium member":
                        "You can buy tokens and give virtual gifts even if you're not Premium member.",
                    'to give to': 'to give to',
                    Date: 'Date',
                    Credit: 'Credit',
                    Debit: 'Debit',
                    Description: 'Description',
                    Total: 'Total',
                    'Give this virtual gift': 'Give this virtual gift',
                    'Put on hold': 'Put on hold',
                    From: 'From: ',
                    To: 'To: ',
                    Attachment: 'Attachment: ',
                    'Choose file': 'Choose file',
                    'Expose you nick to': 'Expose you nick to:',
                    'PM gift': 'Private message (optional)',
                    'By giving this virtual gift':
                        'By giving this virtual gift, your virtual wallet will be debited with the number of tokens corresponding to the value of the gift.',
                    'You can attach a message':
                        'You can attach a message and/or a photo to this virtual gift. This message and this picture will remain confidential, only the recipient can see them.',
                    'Your virtual gift':
                        'Your virtual gift (without your message and your photo) will be displayed on the profile page of the recipient.',
                    'You can choose between 3 options': 'You can choose between 3 options:',
                    'Show your nickname to': '- Show your nickname to: All',
                    'Your nickname will be visible':
                        'Your nickname will be visible to all users on the profile page of the recipient.',
                    'Show your nickname to recipient': '- Show your nickname to: Recipient only',
                    'Your nickname will be visible only to the recipient':
                        'Your nickname will be visible only to the recipient.',
                    'Show your nickname to nobody': '- Show your nickname to: Nobody',
                    'Your nickname will not be visible':
                        'Your nickname will not be visible neither to the recipient nor to other users.',
                    'Your virtual gift will be anonymous': 'Your virtual gift will be anonymous.',
                    'Give a virtual gift': 'Give a virtual gift',
                    'Back to profile of': 'Back to profile of ',
                    'All my virtual gifts': 'All my virtual gifts',
                    'View message': 'View message',
                    'You can hide your virtual gifts':
                        'You can hide your virtual gifts, they will not be displayed on your profile.',

                    // notifications
                    Notifications: 'Notifications',
                    Settings: 'Settings',
                    'Notification services': 'Notification services',
                    notificationsDescription:
                        'Here you can see all notifications or choose what notifications you want to receive. Or do not receive anything at all.',
                    'Notification List': 'Notification List',
                    'Notification settings': 'Notification settings',
                    'You will receive notifications at': 'You will receive notifications at',
                    follower_notification: 'When others start following me:',
                    guestbook_notification: 'When I receive a message in my guestbook:',
                    contribution_notification: 'New contribution of your followee:',
                    'Notify me on site': 'Notify me on site',
                    gift_notification: 'When I receive a virtual gift or Premium membership:',
                    'Notify me by email': 'Notify me by email',
                    'upload a new': 'upload a new',
                    'sent you a': 'sent you',
                    // 'starts following you': 'starts following you',
                    message: 'message',
                    'guestbook message': 'guestbook message',
                    contribution: 'contribution',
                    'New message from': 'New message from ',
                    'New guestbook message': 'New guestbook message from <1>{{userNick}}</1>',
                    'sent you a gift': ' sent you a gift',
                    'sent you a gift notification': ' <0>sent you a </0><1>gift</1>',
                    'starts following you': ' starts following you',
                    'added a new contribution': ' added a new contribution',
                    'Premium gifted': '<0>{{userNick}}</0> gave you {{amount}} days of Premium',
                    'Premium gifted list': '<0>gave you </0><1>{{amount}} days of Premium</1>',
                    'Premium ends':
                        'Your Premium membership expires in {{amount}} days. <3>Renew now</3> to keep accessing the Premium content.',
                    'Premium ended':
                        'Your Premium membership has expired. <1>Renew now</1> to restore your access to the Premium content.',
                    'Close all notifications': 'Close all ({{amount}}) notifications',
                    'Notification settings changed': 'Notification settings changed',

                    // labels popup
                    'Enter your labels, separated by commas': 'Enter your labels, separated by commas en',
                    'Added labels': 'Added labels. Click on label to remove it:',
                    'You dont add any labels yet': 'You dont add any labels yet',
                    'Participate in the labelling of photos and videos by writing a few words to describe them':
                        'Participate in the labelling of photos and videos by writing a few words to describe them.',
                    'Suggest labels:': 'Suggest labels:',
                    'Directly related to the content of the photo or video':
                        'Directly related to the content of the photo or video.',
                    'Only for the photos and videos of very good quality':
                        'Only for the photos and videos of very good quality.',
                    'Only for photos in which the faces are not hidden':
                        'Only for photos in which the faces are not hidden.',
                    'Thank you for labeling this photo': 'Thank you for labeling this photo!',
                    'Thank you for labeling this video': 'Thank you for labeling this video!',
                    'Add labels': 'Add labels',
                    'No labels': 'There is no any labels. Add some!',
                    'Label this photo': 'Label this photo',
                    'Label this video': 'Label this video',
                    'You must be a Premium member to label this photo':
                        'You must be a Premium member to label this photo.',

                    // popups
                    'Invalid email': 'Invalid email',
                    'Email is required': 'Email is required',
                    'Emails must match': 'Emails must match',
                    'Email confirmation is required': 'Email confirmation is required',
                    'New email address': 'New email address',
                    'New email address again': 'New email address again',
                    'Validate email': 'Validate email',
                    'Validation code': 'Validation code',
                    Send: 'Send',
                    'Change nick': 'Change nick',
                    'New nick': 'New nick',
                    nickRules: ' Your request will be rejected in the following cases:',
                    disrespect: '- Nick vulgar or disrespectful.',
                    referring: '- Referring to a trademark or a personality.',
                    capital: '- Nick is in capital letters.',
                    exists: '- Nick already exists.',
                    'Passwords must match': 'Passwords must match',
                    'Password confirmation is required': 'Password confirmation is required',
                    'Password must be at least 4 characters': 'Password must be at least 4 characters',
                    'Password must be at least 6 characters': 'Password must be at least 6 characters',
                    'Password is too common': 'Password is too common',
                    'Same as nick': 'Same as nick',
                    "Password can't be same as nick": "Password can't be same as nick",
                    'Minimum 3 characters': 'Minimum 3 characters',
                    'Maximum 100 characters': 'Maximum 100 characters',
                    'Email or nick is required': 'Email or nick is required',
                    'Email or nick': 'Email or nick',
                    'Minimum 4 digits': 'Minimum 4 digits',
                    'Maximum 4 digits': 'Maximum 4 digits',
                    'Validation token is required': 'Validation token is required',
                    'should be at list 2 symbols': 'should be at list 2 symbols',
                    'You need to be authorized to use this feature': 'You need to be authorized to use this feature.',
                    'You need to become Premium member to use this feature':
                        'You need to become Premium member to use this feature.',
                    'Become a Premium member': 'Become a Premium member',

                    //album
                    'My album': 'My album',
                    'All my photos': 'All my photos',
                    'You don&apos;t have any photos': "You don't have any photos",
                    'But you always can upload some': 'But you always can upload some',
                    'doesn&apos;t have any photos': "doesn't have any photos",
                    'Upload photo': 'Upload photo',
                    'Upload photos': 'Upload photos',

                    //contributions
                    'Become a member': 'Become a member',
                    'to watch more': 'to watch more...',
                    'Login if you are already a member': 'Login if you are already a member',
                    'Please log in to view this photo': 'Please log in to view this photo.',
                    'Become a member to view this photo': 'Become a member to view this photo.',
                    'Become a member to watch the rest of the video':
                        'Become a member to watch the rest of the video...',
                    'Please log in to watch the rest of the video': 'Please log in to watch the rest of the video...',
                    'My contributions': 'My contributions',
                    'You don&apos;t have any contributions': "You don't have any contributions",
                    ' doesn&apos;t have any contributions': " doesn't have any contributions",
                    'All my contributions': 'All my contributions',
                    'Upload contributions': 'Upload your photos and video',
                    'Add contribution to favourites': 'Add contribution to favourites',
                    'My favorites': 'My favorites',
                    'My favorite': 'My favorite',
                    'Enter your labels': 'Enter your labels',
                    'You can add only 3 labels': 'You can add only 3 labels.',
                    'Suggest labels': 'Suggest labels',
                    'Pending moderation': 'Pending moderation',
                    'Waiting for uploading': 'Waiting for upload',
                    'of 12': 'of 12',
                    'submitted on': 'submitted on ',
                    'Please confirm that you authorize publication of these files on':
                        'Please confirm that you authorize publication of these files on bobvoyeur.com',
                    'You need confirm that you are owner and the models on your materials are adult':
                        'You need confirm that you are owner and the models on your materials are adult.',
                    'Minimum 4 attachments in contribution': 'Minimum 4 attachments in contribution.',
                    'Maximum 12 attachments contribution': 'Maximum 12 attachments contribution',
                    'Title is required': 'Title is required',
                    Favorite: 'Favorite',

                    //swiper
                    'to open this photo': 'to open this photo.',
                    'You must be a member': 'You must be a member',
                    'Become an XXL member': 'Become an Premium member',

                    //disclaimer
                    'Site fo adults only!': 'Site fo adults only!',
                    'According to the French law':
                        'According to the French law, the permitted age is 18- Article 488 al. 1 of the Civil Code',
                    'This website is reserved for a major and warned public':
                        'This website is reserved for a major and warned public',
                    'It contains texts, pornographic photos and videos prohibited to minors and which may be offensive to some sensibilities':
                        'It contains texts, pornographic photos and videos prohibited to minors and which may be offensive to some sensibilities',
                    'It is strongly discouraged to sensitive, fragile and impressionable people to access this site; minors mustnt have access under any circumstances':
                        'It is strongly discouraged to sensitive, fragile and impressionable people to access this site; minors mustn’t have access under any circumstances',
                    'I validate the data contained in the following paragraphs after its careful reading, and I agree fully and completely, certifying to fulfill all mentioned conditions, by checking the checkboxprovided':
                        'I validate the data contained in the following paragraphs after its careful reading, and I agree fully and completely, certifying to fulfill all mentioned conditions, by checking the checkboxprovided',
                    'I certify to': 'I certify to',
                    'Be major according to the Act of my country, and to the French law':
                        'Be major according to the Act of my country, and to the French law',
                    'That the laws of my state or my country authorize me to access this site and that bobtv.fr has the right to provide me with such data':
                        'That the laws of my state or my country authorize me to access this site and that bobtv.fr has the right to provide me with such data',
                    'Being informed of pornographic character of content on the server':
                        'Being informed of pornographic character of content on the server',
                    'I declare and acknowledge explicitly': 'I declare and acknowledge explicitly',
                    'Not to be shocked by any type of sexuality, any text, any pornographic pictures or video, and I prohibit myself to prosecute the authors of bobtv.fr due to the data on this site or its consequences':
                        'Not to be shocked by any type of sexuality, any text, any pornographic pictures or video, and I prohibit myself to prosecute the authors of bobtv.fr due to the data on this site or its consequences',
                    'To consult this server only for personal needs and without any involvement of a private company, a government agency, or any other public or private entity':
                        'To consult this server only for personal needs and without any involvement of a private company, a government agency, or any other public or private entity',
                    'I pledge on my honor': 'I pledge on my honor',
                    'never announce the existence of the server to minors':
                        'never announce the existence of the server to minors',
                    'never allow minors to access this server and use all conducive means to prevent access this server by minors':
                        'never allow minors to access this server and use all conducive means to prevent access this server by minors',
                    'never broadcast all or a part of the content published on this server to minors':
                        'never broadcast all or a part of the content published on this server to minors',
                    'to assume my responsibility in case that a minor would have access to the server because of my negligence or my recklessness (lack of protection of my computer, no censorship software, loss or disclosure of the password security ), without recourse against bobtv.fr':
                        'to assume my responsibility in case that a minor would have access to the server because of my negligence or my recklessness (lack of protection of my computer, no censorship software, loss or disclosure of the password security ), without recourse against bobtv.fr',
                    'to fully assume all the consequences of all kinds of declarations at all or partyally inaccurate, without recourse against its authors and bobtv.fr':
                        'to fully assume all the consequences of all kinds of declarations at all or partyally inaccurate, without recourse against its authors and bobtv.fr',
                    'I confirm that I have read and accepted without reservation all the provisions contained in the Terms and Conditions of this server':
                        'I confirm that I have read and accepted without reservation all the provisions contained in the ' +
                        '<1>Terms and Conditions</1> of this server',
                    'You must agree to the Terms and Conditions of Use for accessing this server':
                        'You must agree to the Terms and Conditions of Use for accessing this server.',

                    //search and tags

                    'Search photo and video by tags': 'Search photo and video by tags',
                    'First line of description':
                        'From time to time all erotic photos and porn videos of Bob Voyeur amateurs are sorted by tags with your cooperation. ',
                    'Full description': `From time to time all erotic photos and porn videos of Bob Voyeur amateurs are sorted by tags with your cooperation. 
                    You can participate in this project by assigning tags to the best submitted nude pictures and home made videos you watch every day. 
                    A form for submitting tags is available in every photo and every video.
                    The powerful search engine below and the tag cloud above help you to find photos and videos for your tastes and wishes. `,
                    'Show less': 'Hide more',
                    'Show more': 'Show more',
                    All: 'All',
                    Tout: 'All',
                    Video: 'Video',
                    Materials: 'Materials',
                    'Search results': 'Search results',
                    'What are you looking for': 'What are you looking for?',
                    'View all tags': 'View all tags',
                    'View all searches': 'View all searches',
                    'Tag list': 'Tag list',
                    'The most popular searches': 'The most popular searches',
                    'Search result': '(1)[result];(3)[results]',

                    //contact
                    'Contact info': `Use this contact form for all inquiries about your user account on Bob Voyeur.
                                Help and technical support to access your account and manage your photos and video contributions.
                                Enter a valid email address to receive a reply as soon as possible.`,
                    'Your message has been sent': 'Your message has been sent',
                    Message: 'Message',
                    Status: 'Status:',
                    Free: 'Free',
                    Ok: 'Ok',
                    dots: ': ',

                    //Submit contribution

                    'Submit your contribution': 'Submit your contribution',
                    'Upload your photos and video': 'Upload your photos and video',
                    'Drag your files here': 'Drag your files here',
                    'Add files': 'Add files',
                    'Or add files from': 'Or add files from..',
                    'Photos in jpg format with minimum size': 'Photos in .jpg format with minimum size 1024 x 768.',
                    'Do not upload compressed files': 'Do not upload compressed files (.zip,.rar, ...).',
                    'Video in format': 'Video in format.mpeg.wmv.mov or .avi and not exceed 300Mb',
                    'Low quality photos will not be published': 'Low quality photos will not be published.',
                    'Your comment': 'Your comment',
                    'Name and describe your contribution': 'Name and describe your contribution',
                    'Title of your contribution': 'Title of your contribution',
                    'Submit contribution first line description': `A text description is very appreciated by visitors, this text helps us to
                                            know you better. It is the same for the text of comments you can add on your
                                            photos and video. Take time to describe your contribution and content of
                                            your photo in a few words.`,
                    'Submit contribution second line description': `Your title and text are automatically translated into several languages, so
                                            check your spelling and punctuation to be read and understood by all.`,
                    'Contribution description': 'Contribution description',
                    'Blur my face': 'Blur my face',
                    'I confirm that I am owner of these submitted files':
                        'I confirm that I am owner of these submitted files. I confirm that models on these materials are over 18 years old.',
                    'I authorize the publication of these files':
                        'I authorize the publication of these files on bobvoyeur.com',
                    'Desired section': 'Desired section:',
                    'Conditions of receiving free access to Premium': 'Conditions of receiving free access to Premium:',
                    '15 days free for participating in Hot section (section H)':
                        '15 days free for participating in Hot section (section H).',
                    '15 days free for sending one video over 30 seconds long (section V)':
                        '15 days free for sending one video over 30 seconds long (section V).',
                    '30 days free for attaching a certified photo to your contribution':
                        '30 days free for attaching a certified photo to your contribution.',
                    'Only certified amateurs can receive free access to Premium':
                        'IMPORTANT: Only certified amateurs can receive free access to Premium.',
                    maxNumberOfFiles: 'You can post 12 photos or videos maximum.',
                    acceptFileTypes:
                        'Only photos in .jpg, .jpeg format and videos in .mp4, .mpeg, .wmv, .mov or .avi are accepted.',
                    maxFileSize: 'File must be less than 500MB of size.',
                    minMaterialSize: 'The photo is too small. It must be at least 1280 pixels in width or height.',

                    // upload photo popup

                    'Add a photo': 'Add a photo',
                    'Drag your file here': 'Drag your file here',
                    'Or add from': 'Or add from',
                    Add: 'Add',
                    'Upload in': 'Upload in:',
                    'Photo description': 'Photo description..',
                    'The uploaded image size should be at least': 'The uploaded image size should be at least 1024x768',
                    Private: 'Private',
                    Public: 'Public',
                    'Edit photo': 'Edit photo',
                    Album: 'Album:',

                    // stub

                    'Authorization required': 'Authorization required',
                    'Only registered users have access to this page': 'Only registered users have access to this page',
                    Please: 'Please',
                    login: 'login',
                    'to open this page': 'to open this page',
                    'Please login to open this page': 'Please <1>login</1> to open this page',

                    //pm

                    recipient_busy: 'Recipient is busy.',
                    unanswered: 'Recipient did not answer the call.',
                    declined: 'Recipient rejected to answer the call.',
                    notconnected: 'Connection problem. Failed to start the call.',
                    finished: 'The call was finished.',
                    client_logout: 'Recipient exited the chat.',
                    'no chats':
                        "You don't have active dialogs, but you can start chatting by adding a user via the form on this page",
                    'Waiting for loading chats': 'Waiting for loading chats',
                    'Delete history': 'Delete history',
                    'Revoke permission': 'Revoke permission',
                    'Video call': 'Video call',
                    Archive: 'Archive',

                    //Premium history
                    'Your Premium subscription is active till': 'Your Premium subscription is active till ',
                    'Purchase date': 'Purchase date',
                    'Ticket number': 'Ticket number',
                    Type: 'Type',
                    Purpose: 'Purpose',
                    'Given by': 'Given by <1>{{userNick}}</1>',
                    'given by': 'given by',
                    'Gift for': 'Gift for <1>{{userNick}}</1>',
                    deleted: 'deleted',
                    'Give Premium': 'Give Premium',

                    //gifts
                    'Give a virtual gift to': 'Give a virtual gift to ',
                    'You dont have any gifts': "You don't have any gifts",
                    'doesnt have any gifts': "doesn't have any gifts",
                    'Recipient only': 'Recipient only',
                    Nobody: 'Nobody',
                    'Do you really want to delete this message': 'Do you really want to delete this message?',
                    'an anonymous': 'an anonymous',

                    // my favorites
                    'You have no favorite contributions': 'You have no favorite contributions',
                    'You have no favorite contributors': 'You have no favorite contributors',
                    Delete: 'Delete',

                    // video call
                    'Incoming video call': 'Incoming video call',
                    Answer: 'Answer',
                    Decline: 'Decline',
                    'Video call to': 'Video call to '
                }
            },
            ru: {
                translation: {
                    // common
                    Nick: 'Ник',
                    Sex: 'Пол',
                    Male: 'Мужчина',
                    male: 'Мужчина',
                    Female: 'Женщина',
                    female: 'Женщина',
                    Transvestite: 'Трансвестит',
                    'Female or couple': 'Женщины или пары',
                    'Females or couples': 'Женщины или пары',
                    'Only photo': 'Только фото',
                    'Only video': 'Только видео',
                    'Females and males': 'Женщины и мужчины',
                    'Males only': 'Только мужчины',
                    Couple: 'Пара',
                    couple: 'Пара',
                    years: 'лет',
                    User: 'Пользователь',
                    Location: 'Локация',
                    Cancel: 'Отмена',
                    'Clear filters': 'Сбросить фильтры',
                    'All content': 'Весь контент',
                    'Search content': 'Поиск контента',
                    Search: 'Поиск',
                    Activate: 'Активация',
                    'Log in': 'Вход',
                    'Log in_action': 'Войдите',
                    'Sign up': 'Регистрация',
                    'Password reset': 'Восстановление пароля',
                    Material: 'Тип',
                    Period: 'Период',
                    Contributors: 'Участники',
                    Contributions: 'Подборки',
                    'Last year': 'За год',
                    'Last week': 'За неделю',
                    'Last month': 'За месяц',
                    'All time': 'Все время',
                    friend: 'A friend',
                    friend_male: 'A boyfriend',
                    Disable: 'Отключить',
                    Expiration: 'Истекает: ',
                    Users: 'Пользователи',
                    Select: 'Выберите',
                    'No options': 'Нет варинтов',
                    'The page you were looking for was not found': 'Страница, которую вы пытались открыть, не найдена',

                    // auth forms
                    Enter: 'Вход',
                    Exit: 'Выход',
                    loginError: 'Ошибка входа. Убедитесь, что вводите правильные логин и пароль.',
                    Password: 'Пароль',
                    forgotPassword: 'Забыли пароль?',
                    'Nick is required': 'Необходимо ввести ник',
                    'Password is required': 'Необходимо ввести пароль',
                    'Password confirmation': 'Подтверждение пароля',
                    'Not specified': 'Не указан',
                    'Photo and Video': 'Фото и видео',
                    Photo: 'Фото',
                    forgotPasswordHelpText: 'Введите email или ник чтобы сбросить пароль',
                    'Current password': 'Текущий пароль',
                    'New password': 'Новый пароль',
                    'New password again': 'Новый пароль еще раз',
                    'Activate your account to view this photo': 'Активируйте Ваш аккаунт, чтобы посмотреть это фото',
                    'Activate your account to watch the rest of the video':
                        'Активируйте Ваш аккаунт, чтобы посмотреть продолжение этого видео',
                    'You need to activate your account to use this feature':
                        'Вы должны активировать Ваш аккаунт, чтобы воспользоваться этой функцией',

                    // menu
                    Menu: 'Меню',
                    Home: 'Главная',
                    Forum: 'Форум',
                    Wiki: 'Вики',
                    Links: 'Ссылки',
                    CGU: 'Условия',
                    Information: 'Информация',
                    Contact: 'Связаться с Бобом',
                    'Webcamchat on BobTV': 'Видеочат BobTV',
                    'Les sites de Bob': 'Сайты Боба',
                    'Certified and non-certified': 'Проверенные и нет',
                    'My membership Premium': 'Мое членство Premium',
                    'Single period, without automatic rebilling': 'Разовая оплата, без автоматического продления',
                    'Immediate access': 'Немедленный доступ',
                    'Access Premium features': 'Доступ к функциям Premium',
                    'My virtual gifts': 'Мои виртуальные подарки',
                    'Buy more Premium': 'Купить еще Premium',
                    activationFormText:
                        'Пожалуйста, введите код активации, который мы отправили на вашу электронную почту ' +
                        `<1>{{email}}</1> или щелкнув ссылку активации из сообщения электронной почты. Если вы не получили это письмо, проверьте папку 'Спам' и отметьте его как 'не спам'.`,
                    Reload: 'Перезагрузить',
                    'Oops, something went wrong': 'Упс, что-то пошло не так',
                    'Try to reload the page': 'Попробуйте перезагрузить страницу',
                    'Delete my account': 'Удалить мой аккунт',
                    'You can request deletion':
                        'Вы можете запросить удаление своей учетной записи <span>{{nick}}</span> нажав кнопку ниже.',
                    'All data in this account will be deleted':
                        'Все данные в этом аккаунте будут удалены администратором в кратчайшие сроки (подписка, фотографии, видео, адреса электронной почты...)',
                    'Your request will not be cancelable':
                        'ВНИМАНИЕ: ваш запрос нельзя будет отменить, после удаления будет невозможно получить доступ к вашей учетной записи, вашим данным и вашей подписке.',

                    //payment
                    'Buy Premium Membership': 'Купить Premium партнерство',
                    'Give Premium Membership': 'Подарить Premium партнерство',
                    'Membership history': 'История подписки',
                    'Choose your Premium plan': 'Выберете свой Premium план',
                    "Choose what Premium plan you'll give to": 'Выберите, какой Premium план вы подарите',
                    'More information about Functions Premium': 'Больше информации о функциях Premium',
                    Functions: 'Функции',
                    contributionsAndMaterialsCount:
                        'чтобы смотреть все архивы : <1>{{contributionsCount}}</1> подборок, <3>{{materialCount}}</3> любительских фото и видео',
                    'to view the contributions of Hot section, Premium themes':
                        'чтобы смотреть подборки из секции Горячее, специальные темы Premium',
                    'to view all videos of long duration': 'чтобы смотреть все видео длительной продолжительности',
                    'to download and save videos of original quality':
                        'чтобы скачивать и сохранять все видео в оригинальном качестве',
                    'to view full size photos with greater size 1280x960':
                        'чтобы смотреть полноразмерные фото в большем разрешении 1280x960',
                    'to upload 40 photos to your profile': 'чтобы добавить до 40 фото в Ваш профайл',
                    'to view photos in Premium albums of all members':
                        'чтобы иметь доступ к фото из альбомов Premium всех членов',
                    'Secure server, payment page is encrypted with 128 Bit SSL':
                        'Безопасный сервер, платежная страница зашифрована 128-битным SSL',
                    'Card number': 'Номер карты',
                    'Expiration date': 'Срок годности',
                    'Security code': 'Код защиты',
                    'Enter security code': 'Введите код защиты',
                    'This code consists of the last 3 digits at the back side of most credit cards':
                        'Этот код состоит из последних 3 цифр на обратной стороне большинства кредитных карт',
                    'Your email address': 'Ваш email адрес',
                    'Payment first line':
                        'Подтверждение оплаты будет отправлено на этот адрес электронной почты. Обязательно указывайте действующий адрес. ',
                    'Payment second line': 'Проверьте папку со спамом, если вы не получили сообщение по вашей почте',
                    inbox: '',
                    Confirm: 'Принять',
                    'should be chosen': 'должен быть выбран',
                    'Credit card number is required': 'Введите номер кредитной карты',
                    'Invalid credit card number': 'Не верная кредитаня карта',
                    'You can attach a message for the recipient, if you want':
                        'Вы можете отсавить сообщение, если хотите этого',
                    'Replenish your wallet': 'Пополнить ваш кошелек',
                    Membership: 'Партнерство',
                    singlePaymentText:
                        '<0>Разовый платеж, без автоматического продления.</0> С вас будет взиматься <2>только</2> раз.',
                    'Enter a card number': 'Введите номер карты',
                    'MM/YY': 'MM/YY',
                    'Card number is invalid': 'Неверный номер карты',
                    'Enter an expiry date': 'Введите дату истечения срока действия',
                    'Expiry month must be between 01 and 12':
                        'Месяц истечения срока действия должен быть между 01 и 12',
                    'Expiry year cannot be in the past': 'Год истечения срока действия не может быть в прошлом',
                    'Expiry date cannot be in the past': 'Срок действия не может быть в прошлом',
                    'Expiry date is invalid': 'Недопустимая дата истечения срока действия',
                    'Enter a CVC': 'Введите CVC',
                    'CVC is invalid': 'CVC недействителен',

                    // index
                    'Index title': 'Bob Voyeur это сайт \n посвященный эксгибиционизму и вуайеризму',
                    filters: 'фильтры',
                    Filters: 'Фильтры',
                    'Apply filters': 'Применить фильтры',
                    'Only Free': 'Только бесплатные',
                    'Only Certified': 'Только проверенные',
                    'Amateur contributions': 'Любительские подборки',
                    'Videochat rooms to exhibit on webcam for amateur libertines and voyeurs':
                        'Комнаты видеочата, с использованием веб-камер, для любителей разврата и вуайеристов',
                    'Nudity and sex webcams are accepted on chat rooms':
                        ' Ногота и секс на вебкамеру разрешены в комнатах чата',
                    'Load more': 'Загрузить еще',
                    Online: 'Онлайн',
                    Offline: 'Оффлайн',
                    'Certified info': `Несколько сотен любителей и пар взрослых любителей уже проверены на Bob Voyeur.
Чтобы сделать это, стать проверенным любителем, просто добавьте к своей подборке фото, которое показывает что Вы подлинная любительская пара или реальный любитель, фанат сайта Bob Voyeur.
На фото обязательно должна присутствовать девушка и название нашего сайта. Вы можете написать "Bob Voyeur" на листе бумаги, на Вашей коже или на экране компьютера ... Будьте изобретательны ;-)`,
                    'Best info': `Раздел «Лучшие фото» — это очень личный выбор Боба среди всех личных фотографий поклонников Bob Voyeur. Я, Боб, выбираю лучшие горячие фото из всего материала, присланного мне взрослыми мужчинами, горячими умелыми женщинами и любительскими настоящими парочками. Коллекция лучших эротических и порно фото олицетворяет дух красоты, любви и секса, который важен для Боба Вуайера. Фотографии тщательно отобраны с точки зрения излучаемой ими эротики, качества изображения и выбора оригинального фона.`,
                    Locate: 'Найдите',
                    'Certified females and couples': 'Проверенные женщины и пары',
                    'The Best submitted photos': 'Лучшие опубликованные фотографии',
                    'Female and couple photos': 'Фотографии женщин и пар',
                    'Female and couple videos': 'Видео женщин и пар',
                    'Male photos and videos': 'Фото и видео мужчин',
                    'The best photos': 'Лучшие фотографии',

                    // profiles search
                    Profiles: 'Профили',
                    Profiles_page: 'Профили',
                    'Profiles info': 'На этой странице вы можете найти все профили, зарегестрированные на сайте.',
                    'With album photo': 'С фотографиями в альбоме',
                    'Certified amateurs': 'Проверенное любительское',
                    'With contributions': 'С подборками',
                    'Online within 10 days': 'Онлайн в течении 10 дней',
                    'User nick': 'Ник пользователя',
                    Gender: 'Пол',
                    Age: 'Возраст',
                    Country: 'Страна',
                    Region: 'Регион',
                    'Show more filters': 'Больше фильтров',
                    Profile: 'Профиль',

                    // profile
                    'Last online': 'Был онлайн ',
                    'time format': 'DD/MM/YYYY [в] HH:mm',
                    views: 'просмотры',
                    followers: 'подписчики',
                    block: 'заблокировать',
                    unblock: 'разблокировать',
                    'Public album': 'Публичный альбом',
                    'All photos': 'Все фото',
                    'All contributions': 'Все подборки',
                    Guestbook: 'Гостевая книга',
                    'Add a message': 'Оставить сообщение',
                    About: 'Обо мне',
                    Change: 'Изменить',
                    'Change email': 'Изменить email',
                    'Change password': 'Изменить пароль',
                    'Allow private messages': 'Разрешить приватные сообщения',
                    'Tell something about you': 'Расскажите что-нибудь о себе',
                    Save: 'Сохранить',
                    Reset: 'Сбросить',
                    "amateur sur Bob Voyeur le site libertin de l'exhib et de l'échangisme":
                        'любитель на Bob Voyeur развратный сайт для выставок и свинга',
                    'All of my guestbook': ' Все сообщения в гостевой книге',
                    'All guestbook messages': 'Все сообщения в гостевой книге',
                    'Current email address': 'Текущий email адрес:',
                    'Photos of your public album can be viewed by all registered visitors':
                        'Фотографии вашего общедоступного альбома могут просматривать все зарегистрированные посетители.',
                    'Photos of your Premium album can be viewed by Premium members only':
                        'Фотографии из вашего альбома Premium могут просматривать только участники Premium.',
                    'Your private album is visible only to the members of your friends list':
                        'Ваш личный альбом виден только вашим друзьям.',
                    'A text description':
                        'Текстовое описание к каждой вашей фотографии всегда нравится посетителям, этот текст помогает узнать вас лучше. Пожалуйста, опишите содержание ваших фотографий в нескольких словах.',
                    'of 20': ' из 20',
                    'of 40': ' из 40',
                    public: 'Публичный',
                    private: 'Приватный',
                    Give: 'Подарить',
                    'My albums': 'Мои альбомы',
                    'Private album': 'Приватный альбом',
                    'Premium album': 'Premium альбом',
                    'All virtual gifts': 'Все виртуальные подарки',
                    'Participations in the forum': 'Участие в форуме',
                    'My participations in the forum': 'Мое участие на форуме',
                    'My participations in the forum button': 'Мое участие на форуме',
                    'I have not yet participated in the forum':
                        "Я еще не принял участие в <a href='/forum/'>форуме</a>.",

                    //geo
                    'Around me': 'Рядом со мной',
                    'Search radius (km)': 'Радиус поиска (км)',
                    'Move the cursor to display my position with more or less precision':
                        'Переместите курсор, чтобы отобразить мою позицию с большей или меньшей точностью:',
                    'Share my position on the map': 'Поделиться моим расположением на карте',
                    'My mood': 'Мой статус:',
                    'Sort by': 'Сортировать по:',
                    'Near to me': 'Рядом со мной',
                    'Change your humor': 'Поменять мой статус',
                    Humor: 'Статус...',
                    'Users in table': '(1)[пользователь];(2)[пользователя];(3)[пользователей];',
                    map_distance: 'радиус {{count}}м.',
                    'exact position': 'точное положение',
                    'approximate position': 'приблизительное положение',
                    'Login to estimate distances': 'Войдите, чтобы оценить расстояния',
                    'Share your position on the map to estimate distances':
                        'Поделитесь своим положением на карте, чтобы оценить расстояния.',
                    'Enable geolocation in your browser settings to estimate distances':
                        'Включите геолокацию в настройках вашего браузера, чтобы оценить расстояние.',
                    'Higher precision only available on mobile devices':
                        'Более высокая точность доступна только на мобильных устройствах',
                    'geo time format': 'DD/MM [в] HH:mm',
                    'Exact position on': 'Точная позиция на',
                    'Approximate position on': 'Приблизительное положение на',
                    exactly: 'точно',
                    approximately: 'приблизительно',
                    km: 'км',
                    m: 'м',
                    'Your location': 'Ваше местоположение',
                    //FAQ geo
                    'Authorize the bobvoyeur-com site to access your location in your browser':
                        'Разрешите сайту bobvoyeur.com получать доступ к вашему местоположению в вашем браузере.',
                    'Share your position on the map to estimate the distances between you and other members':
                        'Поделитесь своим местоположением на карте, чтобы оценить расстояние между вами и другими участниками.',
                    ThirdLineFAQGeo:
                        'Перемещайте курсор, чтобы отобразить свое положение с большей или меньшей точностью. Если вы используете телефон, вы можете сообщить свое точное местоположение с помощью GPS вашего устройства. Если вы используете компьютер, ваше местоположение на карте будет приблизительным.',
                    'Write a short mood text to describe your interests':
                        'Напишите короткий текст, описывающий ваше настроение.',
                    FivesLineFAQGeo:
                        'На карте отображаются участники, которые находятся в сети или подключались к этой услуге за последние 7 дней. Их положение является точным или приблизительным в зависимости от выбора точности.',

                    // blacklist
                    'My blacklist': 'Черный список',
                    'My blocked users': 'Черный список',
                    'Blacklist description':
                        'Если вы добавите ник в ваш черный список, этот пользователь больше не сможет отправлять вам сообщения на сайте',
                    Duration: 'Продолжительность',
                    Reason: 'Причина',
                    Block: 'Заблокировать',
                    Unblock: 'Разблокировать',
                    'Are you sure?': 'Вы уверены?',
                    'Do you want to unblock': 'Вы хотите разблокировать',
                    'Describe your reason': 'Назовите причину блокировки',
                    'For how long?': 'На какой срок?',
                    '24 hours': '24 часа',
                    Permanent: 'Навсегда',
                    'Add to blacklist': 'Заблокировать',
                    'Why would you add to your black list': 'Почему вы хотите заблокировать',
                    'There are no users in your blacklist': 'В вашем черном списке никого нет',
                    'List of blocked users': 'Заблокированные пользователи',
                    'Adding date': 'Дата добавления',
                    'Time of blocking': 'Время блокировки',

                    // following
                    follow: 'Подписаться',
                    unfollow: 'Отписаться',
                    Following: 'Подписки',
                    Followers: 'Подписчики',
                    'Follow user': 'Подписаться',
                    'Search by name': 'Поиск по имени',
                    'My followers': 'Мои подписчики',
                    'My followees': 'Мои подписки',
                    'Members I follow': 'Мои подписки',
                    'have no followers yet': ' не имеет подписпичиков',
                    'have no followed users': ' не имеет подписписчиков',
                    'You have no followers yet': 'У вас пока нет подписчиков',
                    'There are no followed users': 'Вы пока ни на кого не подписаны',

                    // messages
                    'My messages': 'Сообщения',
                    'Send a message': 'Отправить сообщение',
                    Messages: 'Сообщения',
                    'My requests': 'Мои запросы',
                    'Private messages': 'Приватные сообщения',
                    'New message': 'Новое сообщение',

                    // guestbook
                    'My guestbook': 'Гостевая книга',
                    'You dont have any guestbook messages': 'У вас нет гостевых сообщений',
                    ' doesnt have any guestbook messages': ' не имеет гостевых сообщений',
                    'Disable my Guestbook': 'Отключить мою гостевую книгу',
                    'Add message to Guestbook of': 'Добавить сообщение в гостевую книгу для',

                    // wallet
                    'My wallet': 'Кошелек',
                    'Virtual gifts': 'Виртуальные подарки',
                    'Pending gifts': 'Ожидающие подарки',
                    History: 'История',
                    Replenish: 'Пополнить',
                    points: 'токенов',
                    tokens: 'токенов',
                    days: 'дней',
                    'Your virtual wallet contains points': 'Ваш виртуальный кошелек содержит токены.',
                    'You can purchase these points by crediting your virtual wallet by a secure online payment':
                        'Вы можете приобрести эти токены, пополнив свой виртуальный кошелек безопасным онлайн-платежом.',
                    'The points accumulated in your virtual wallet have unlimited validity':
                        'Токены, накопленные в вашем виртуальном кошельке, имеют неограниченный срок действия.',
                    'You can use these points to offer virtual gifts to any bobVoyeur user by your choice':
                        'Вы можете использовать эти токены, чтобы дарить виртуальные подарки любому пользователю bobVoyeur по вашему выбору.',
                    "You can buy points and give virtual gifts even if you're not Premium member":
                        'Вы можете покупать токены и дарить виртуальные подарки, даже если вы не являетесь участником Premium.',
                    'to give to': 'адресован',
                    Date: 'Дата',
                    Credit: 'Кредит',
                    Debit: 'Дебит',
                    Description: 'Описание:',
                    Total: 'Итого',
                    'Give this virtual gift': 'Подарить',
                    'Put on hold': 'Положить на хранение',
                    From: 'От кого: ',
                    To: 'Кому: ',
                    Attachment: 'Вложение: ',
                    'Choose file': 'Выберите файл',
                    'Expose you nick to': 'Поделиться своим ником:',
                    'PM gift': 'Личное сообщение (не обязательно)',
                    'By giving this virtual gift':
                        'Подарив этот виртуальный подарок, с вашего виртуального кошелька будет списано количество токенов, соответствующее стоимости подарка.',
                    'You can attach a message':
                        'Вы можете прикрепить сообщение и/или фотографию к этому виртуальному подарку. Это сообщение и эта картинка останутся конфиденциальными, их сможет увидеть только получатель.',
                    'Your virtual gift':
                        'Ваш виртуальный подарок (без вашего сообщения и вашей фотографии) будет отображаться на странице профиля получателя.',
                    'You can choose between 3 options': 'Вы можете выбрать один из 3 вариантов:',
                    'Show your nickname to': '- Показать всем: All',
                    'Your nickname will be visible':
                        'Ваш никнейм будет виден всем пользователям на странице профиля получателя.',
                    'Show your nickname to recipient': '- Показать свой никнейм: Recipient only',
                    'Your nickname will be visible only to the recipient': 'Ваш никнейм будет виден только получателю.',
                    'Show your nickname to nobody': '- Показать свой никнейм: Nobody',
                    'Your nickname will not be visible':
                        'Ваш никнейм не будет виден ни получателю, ни другим пользователям.',
                    'Your virtual gift will be anonymous': 'Ваш виртуальный подарок будет анонимным.',
                    'Give a virtual gift': 'Сделать виртуальный подарок',
                    'Back to profile of': 'Вернуться в профиль  ',
                    'View message': 'Посмотреть сообщение',
                    'You can hide your virtual gifts':
                        'Вы можете скрыть свои виртуальные подарки, они не будут отображаться в вашем профиле.',

                    // notifications
                    Notifications: 'Уведомления',
                    Settings: 'Настройки',
                    'Notification services': 'Уведомления',
                    notificationsDescription:
                        'Здесь вы можете просмотреть все уведомления или выбрать, какие уведомления вы хотите получать, либо не получать их совсем',
                    'Notification List': 'Список уведомлений',
                    'Notification settings': 'Настройки уведомлений',
                    'You will receive notifications at': 'Вы будете получать уведомления на',
                    follower_notification: 'Когда кто-то подписывается на меня:',
                    guestbook_notification: 'Когда я получаю новое сообщение в гостевой книге:',
                    contribution_notification: 'Новый материалы в моих подписках:',
                    gift_notification: 'Когда мне дарят подарок или членство Premium:',
                    'Notify me on site': 'Уведомление на сайте',
                    'Notify me by email': 'Уведомление на email',
                    'upload a new': 'загрузил новый',
                    'sent you a': 'отправил вам',
                    // 'starts following you': 'подписался на вас',
                    message: 'сообщение',
                    'guestbook message': 'сообщение в гостевой книге',
                    contribution: 'материал',
                    'New message from': 'Новое сообщение от ',
                    'New guestbook message': 'Новое сообщение в гостевой книге от <1>{{userNick}}</1>',
                    'sent you a gift': ' отправил вам подарок',
                    'sent you a gift notification': ' <0>отправил вам </0><1>подарок</1>',
                    'starts following you': ' подписался на вас',
                    'added a new contribution': ' добавил новую подборку.',
                    'Premium gifted': '<0>{{userNick}}</0> подарил вам {{amount}} дней подписки Premium',
                    'Premium gifted list': '<0>подарил вам </0><1>{{amount}} дней подписки Premium</1>',
                    'Premium ends':
                        'Ваша подписка Premium заканчивается через {{amount}} дней. <3>Продлите сейчас</3> чтобы сохранить ваш доступ к Premium контенту.',
                    'Premium ended':
                        'Ваша подписка Premium закончилась. <1>Продлите сейчас</1> чтобы сохранить ваш доступ к Premium контенту.',
                    'Close all notifications': 'Закрыть все ({{amount}}) уведомления',
                    'Notification settings changed': 'Настройки уведомлений сохранены',

                    // labels popup
                    'Enter your labels, separated by commas': 'Введите ваши теги, разделяя запятой',
                    'Added labels': 'Добавленные теги. Кликните на тег чтобы удалить его:',
                    'You dont add any labels yet': 'Вы пока не добавили ни одного тега',
                    'Participate in the labelling of photos and videos by writing a few words to describe them':
                        'Участвуйте в добавлении тегов у фото и видео, написав несколько слов о них.',
                    'Suggest labels:': 'Предлагайте теги:',
                    'Directly related to the content of the photo or video':
                        'Напрямую относящиеся к содержимому фото или видео.',
                    'Only for the photos and videos of very good quality': 'Только для фото и видео хорошего качества.',
                    'Only for photos in which the faces are not hidden': 'Только для фото, на которых видно лицо.',
                    'Thank you for labeling this photo': 'Спасибо за добавление тегов к этому фото!',
                    'Thank you for labeling this video': 'Спасибо за добавление тегов к этому видео!',
                    'Add labels': 'Добавить',
                    'No labels': 'Здесь пока нет никаких тегов. Вы можете их добавить!',
                    'Label this photo': 'Добавить теги на фото',
                    'Label this video': 'Добавить теги на видео',
                    'You must be a Premium member to label this photo':
                        'У вас должен быть Premium чтобы добавлять теги к этому фото.',

                    // popups
                    'Invalid email': 'Неверный email',
                    'Email is required': 'Необходимо ввести email',
                    'Emails must match': 'Адреса должны совпадать',
                    'Email confirmation is required': 'Необходимо ввести подтверждение email',
                    'New email address': 'Новый email адрес',
                    'New email address again': 'Новый email адрес еще раз',
                    'Validate email': 'Подтверждение email',
                    'Validation code': 'Код подтверждения',
                    Send: 'Отправить',
                    'Change nick': 'Сменить ник',
                    'New nick': 'Новый ник',
                    nickRules: ' Ваш запрос будет отклонен в следующих случаях:',
                    disrespect: '- Вульгарный или неуважительный ник.',
                    referring: '- Ссылается на товарный знак или личность.',
                    capital: '- Ник состоит из заглавных букв.',
                    exists: '- Ник уже существует.',
                    'Passwords must match': 'Пароли должны совпадать',
                    'Password confirmation is required': 'Требуется подтверждение пароля',
                    'Password must be at least 4 characters': 'Пароль должен быть не менее 4 символов',
                    'Password must be at least 6 characters': 'Пароль должен быть не менее 6 символов',
                    'Password is too common': 'Пароль слишком простой',
                    'Same as nick': 'Повторяет ник',
                    "Password can't be same as nick": 'Пароль не может совпадать с ником',
                    'Minimum 3 characters': 'Минимум 3 символа',
                    'Maximum 100 characters': 'Максимум 100 символов',
                    'Email or nick is required': 'email или ник - обязаетлные поля',
                    'Email or nick': 'Email или ник',
                    'Minimum 4 digits': 'Минимум 4 цифры',
                    'Maximum 4 digits': 'Максимум 4 цифры',
                    'Validation token is required': 'Требуется токен валидации',
                    'should be at list 2 symbols': 'должно быть как минимум 2 символа',
                    'You need to be authorized to use this feature':
                        'Чтобы использовать эту функцию требуется авторизация.',
                    'You need to become Premium member to use this feature':
                        'Вам нужно быть Premium пользователем, чтобы использовать эту функцию.',
                    'Become a Premium member': 'Стать Premium пользователем',

                    //album
                    'My album': 'Мой альбом',
                    'All my photos': 'Все мои фотографии',
                    'You don&apos;t have any photos': 'У вас нет фотографий',
                    'But you always can upload some': 'Но вы всегда можете их загрузить',
                    'doesn&apos;t have any photos': 'не имеет фотографий',
                    'Upload photo': 'Загрузить фото',
                    'Upload photos': 'Загрузить фотографии',

                    //contributions
                    'Become a member': 'Станьте членом',
                    'to watch more': 'чтобы посомтреть дальше',
                    'Login if you are already a member': 'Войдтие, если вы уже член',
                    'Please log in to view this photo': 'Пожалуйста авторизуйтесь для просмотра этого фото.',
                    'Become a member to view this photo': 'Для просмотра этого фото нужна Premium-подписка.',
                    'Become a member to watch the rest of the video':
                        'Чтобы смотреть видео до конца, нужна Premium-подписка.',
                    'Please log in to watch the rest of the video':
                        'Чтобы смотреть видео до конца, нужна Premium-подписка.',
                    'My contributions': 'Мои подборки',
                    'You don&apos;t have any contributions': 'У вас нет подборок',
                    ' doesn&apos;t have any contributions': ' не имеет подборок',
                    'All my contributions': 'Все мои подборки',
                    'Upload contributions': 'Загрузить подборку',
                    'Add contribution to favourites': 'Добавить подборку в избранное',
                    'My favorites': 'Мои избранные',
                    'My favorite': 'Мои избранные',
                    'Enter your labels': 'Введите ваши теги',
                    'You can add only 3 labels': 'Вы можете добавить только 3 тега.',
                    'Suggest labels': 'Предложить теги',
                    'Pending moderation': 'Ожидает модерации',
                    'Waiting for uploading': 'Ожидает загрузку',
                    'of 12': 'из 12',
                    'submitted on': 'опубликована ',
                    'Please confirm that you authorize publication of these files on':
                        'Пожалуйста, подтвердите, что вы разрешаете публикацию этих файлов на bobvoyeur.com.',
                    'You need confirm that you are owner and the models on your materials are adult':
                        'Вам необходимо подтвердить, что вы являетесь владельцем, а модели на ваших материалах совершеннолетние.',
                    'Minimum 4 attachments in contribution': 'Должно быть как минимум 4 вложения в подброке.',
                    'Maximum 12 attachments contribution': 'Должно быть не больше 12 вложений в подброке',
                    'Title is required': 'Заголовок - обязательное поле',
                    Favorite: 'Избранное',

                    //swiper
                    'to open this photo': 'чтобы открыть это фото.',
                    'You must be a member': 'Вы должны быть членом',
                    'Become an XXL member': 'Стать Premium членом',

                    //disclaimer
                    'Site fo adults only!': 'Сайт только для взрослых!',
                    'According to the French law':
                        'Согласно французским законам, совершеннолетием считается возраст 18 лет - Статья 488 п. 1 Гражданского Кодекса',
                    'This website is reserved for a major and warned public':
                        'Этот веб-сайт предназначен только для взрослых',
                    'It contains texts, pornographic photos and videos prohibited to minors and which may be offensive to some sensibilities':
                        'Он содержит тексты, порнографические фотографии и видео, запрещенные для несовершеннолетних, а также, возможно, оскорбительные для чувств некоторых',
                    'It is strongly discouraged to sensitive, fragile and impressionable people to access this site; minors mustnt have access under any circumstances':
                        'Посещать этот сайт настоятельно не рекомендуется чувствительным, хрупким и впечатлительным людям; несовершеннолетние ни в коем случае не должны посещать этот сайт',
                    'I validate the data contained in the following paragraphs after its careful reading, and I agree fully and completely, certifying to fulfill all mentioned conditions, by checking the checkboxprovided':
                        'Я подтверждаю все данные, содержащиеся в следующих пунктах, после тщательного прочтения, и таким образом проявляю свое полное согласие, обязуюсь выполнить все условия, содержащиеся здесь, отметив флажек предназдаченный для этой цели',
                    'I certify to': 'Настоящим подтверждаю',
                    'Be major according to the Act of my country, and to the French law':
                        'я являюсь совершеннолетним, в соответствии с законом, который действует в моей стране и в соответствии с французским законодательством',
                    'That the laws of my state or my country authorize me to access this site and that bobtvfr has the right to provide me with such data':
                        'законы моего региона и моей страны разрешают мне посещать этот сайт, и bobvoyeur.com имеет право предоставлять мне данные такого рода',
                    'Being informed of pornographic character of content on the server':
                        'я проинформирован о порнографическом характере сайта, который я посещаю',
                    'I declare and acknowledge explicitly': 'Заявляю и признаю явно',
                    'Not to be shocked by any type of sexuality, any text, any pornographic pictures or video, and I prohibit myself to prosecute the authors of bobtvfr due to the data on this site or its consequences':
                        'я не буду шокирован от любого типа сексуальности, любого текста, любого изображения или порнографического видео, и я запрещаю себе преследовать bobvoyeur.com из-за данных представленных на этом сайте или последствий размещения этих данных',
                    'To consult this server only for personal needs and without any involvement of a private company, a government agency, or any other public or private entity':
                        'я буду просматривать этот сайт только для личных нужд и без какого-либо участия, в любой форме, частных компаний, государственных органов или любого другого государственного или частного субъекта',
                    'I pledge on my honor': 'Клянусь своей честью',
                    'never announce the existence of the server to minors':
                        'никогда не рассказывать о существовании этого сайта несовершеннолетним',
                    'never allow minors to access this server and use all conducive means to prevent access this server by minors':
                        'никогда не позволять несовершеннолетним посещать этот сайт и использовать все средства, чтобы предотвратить для них любой незначительный доступ к этому сайту',
                    'never broadcast all or a part of the content published on this server to minors':
                        'никогда не транслировать все или часть содержания опубликованного на данном сайте несовершеннолетним по какой-либо причине',
                    'to assume my responsibility in case that a minor would have access to the server because of my negligence or my recklessness (lack of protection of my computer, no censorship software, loss or disclosure of the password security ), without recourse against bobtvfr':
                        'понимать свою ответственность в случае, если несовершеннолетний будет иметь доступ к этому сайту из-за моей небрежности или моей неосторожности (отсутствие защиты на моем компьютере, отсутствие цензуры программного обеспечения, раскрытие или потеря безопасности пароля), не прибегая к действиям против bobvoyeur.com и его авторов',
                    'to fully assume all the consequences of all kinds of declarations at all or partyally inaccurate, without recourse against its authors and bobtvfr':
                        'понимать все последствия, без оговорок любого рода, полностью или частично неточной информации размещенной на сайте, не прибегая действиям против bobvoyeur.com и его авторов',
                    'I confirm that I have read and accepted without reservation all the provisions contained in the Terms and Conditions of this server':
                        'Я подтверждаю, что я прочел и принял без оговорок все пункты ' +
                        '<1>Постановлений и Условий</1> этого сервера',
                    'You must agree to the Terms and Conditions of Use for accessing this server':
                        'Вы должны согласиться с Постановлениями и Условиями использования чтобы получить доступ к этому серверу.',

                    //search and tags

                    'Search photo and video by tags': 'Поиск фото и видео по тегу',
                    'First line of description':
                        'Время от времени все эротические фото и порно видео любителей Bob Voyeur сортируются по тегам при вашем сотрудничестве. ',
                    'Full description': `Время от времени все эротические фото и порно видео любителей Bob Voyeur сортируются по тегам при вашем сотрудничестве.  
                    Вы можете поучавствовать в этом проекте отправив теги к лучшим фото и видео которые вы ежедневно смотрите.
                    Форма для отправки тегов доступна на каждой странице фото и видео. Поисковая система ниже и облако тегов помогают вам найти фото и видео согласно вашим вкусам и желаниям. `,
                    'Show less': 'Скрыть',
                    'Show more': 'Еще',
                    All: 'Все',
                    Tout: 'Все',
                    Video: 'Видео',
                    Materials: 'Материалы',
                    'Search results': 'Результаты поиска',
                    'What are you looking for': 'Что вы ищете?',
                    'View all tags': 'Все теги',
                    'View all searches': 'Все поисковые запросы',
                    'Tag list': 'Список тегов',
                    'The most popular searches': 'Самые популярные поисковые запросы',
                    'Search result': '(1)[результат];(2)[результата];(3)[результатов];',

                    //contact
                    'Contact info': `Используйте эту контактную форму для любых запросов касающихся вашей учетной записи пользователя на Bob Voyeur.
                                 Справка и техническая поддержка для доступа к вашей учетной записи и управлению вашими фотографиями и видео подборками.
                                 Чтобы получить ответ как можно скорее, введите действительный адрес электронной почты.`,
                    'Your message has been sent': 'Ваше сообщение отправлено.',
                    Message: 'Сообщение',
                    Status: 'Статус:',
                    Free: 'Бесплатный',
                    Ok: 'Ok',
                    dots: ': ',

                    //Submit contribution

                    'Submit your contribution': 'Загрузить подборку',
                    'Upload your photos and video': 'Загрузите ваши фото и видео',
                    'Drag your files here': 'Перетащите ваши файлы сюда',
                    'Add files': 'ДОбавить файлы',
                    'Or add files from': 'Или добавить файлы из...',
                    'Photos in jpg format with minimum size':
                        'Фотографии в формате .jpg с минимальным размером 1024 x 768.',
                    'Do not upload compressed files': 'Не загружайте сжатые файлы (.zip,.rar, ...).',
                    'Video in format': 'Видео в форматах.mpeg.wmv.mov или .avi и не превышающие 300мб.',
                    'Low quality photos will not be published': 'Фотографии в низком качестве не будут опубликованы.',
                    'Your comment': 'Ваш комментарий',
                    'Name and describe your contribution': 'Название и описание вашей подборки',
                    'Title of your contribution': 'Заголовок вашей подборки',
                    'Submit contribution first line description': `Текстовое описание очень нравится посетителям, этот текст помогает нам
                                                                узнать тебя лучше. То же самое и с текстом комментариев, которые вы можете добавить к своим фотографиям и видео. 
                                                                Найдите время, чтобы описать свою подборку и содержание ваших фотографий в нескольких словах..`,
                    'Submit contribution second line description': `Ваш заголовок и текст автоматически переводятся на несколько языков, поэтому
                                                                проверьте орфографию и пунктуацию, чтобы все можно было прочитать и понять.`,
                    'Contribution description': 'Описание подборки',
                    'Blur my face': 'Замазать мое лицо',
                    'I confirm that I am owner of these submitted files':
                        'Я подтверждаю, что являюсь владельцем этих загружаемых файлов. Подтверждаю, что модели на данных материалах старше 18 лет.',
                    'I authorize the publication of these files': 'Я разрешаю публикацию этих файлов на bobvoyeur.com',
                    'Desired section': 'Желаемый раздел:',
                    'Conditions of receiving free access to Premium': 'Условия получения бесплатного членства Premium:',
                    '15 days free for participating in Hot section (section H)':
                        '15 дней бесплатно, если подборка опубликована в секции Hot (секция H).',
                    '15 days free for sending one video over 30 seconds long (section V)':
                        '15 дней бесплатно, если вы добавили продолжительное видео более 30 секунд (только пары парень+девушка) (секция V).',
                    '30 days free for attaching a certified photo to your contribution':
                        '30 дней бесплатно, если фотография вашей подборки содержит подтверждающее фото.',
                    'Only certified amateurs can receive free access to Premium':
                        'ВАЖНО: Только подтвержденные любители могут получать бесплатный доступ к Premium.',
                    maxNumberOfFiles: 'Вы можете опубликовать максимум 12 фото или видео.',
                    acceptFileTypes:
                        'Принимаются фотографии только в формате .jpg, .jpeg и видео в формате .mp4, .mpeg, .wmv, .mov или .avi.',
                    maxFileSize: 'Размер файла не должен превышать 500 МБ.',
                    minMaterialSize:
                        'Фото слишком маленькое. Оно должен быть не менее 1280 пикселей по ширине или высоте.',

                    // upload photo popup

                    'Add a photo': 'Добавить фото',
                    'Drag your file here': 'Пренесите ваш файл сюда',
                    'Or add from': 'Или добавьте из',
                    Add: 'Добавить',
                    'Upload in': 'Зогрузить в:',
                    'Photo description': 'Описание фотографии..',
                    'The uploaded image size should be at least':
                        'Загружаемое изображение должно быть как минимум 1024x768',
                    Private: 'Приватный',
                    Public: 'Публичный',
                    'Edit photo': 'Изменить фото',
                    Album: 'Альбом:',

                    // stub

                    'Authorization required': 'Необходима авторизация',
                    'Only registered users have access to this page':
                        'Только зарегистрированные пользователи имеют доступ на эту страницу',
                    'Please login to open this page': 'Пожалуйста <1>войдите</1> чтобы открыть эту страницу',

                    //pm

                    recipient_busy: 'Собеседник занят.',
                    unanswered: 'Собеседник не ответил на звонок.',
                    declined: 'Собеседник отклонил звонок.',
                    notconnected: 'Проблемы с соединением. Не удалось начать звонок.',
                    finished: 'Звонок завершен.',
                    client_logout: 'Собеседник вышел из чата.',
                    'no chats':
                        'У вас нет активных диалогов, но вы можете начать общение добавив пользователя через форму на этой странице.',
                    'Waiting for loading chats': 'Ожидание загрузки чатов',
                    'Delete history': 'Удалить историю',
                    'Revoke permission': 'Отозвать права',
                    'Video call': 'Видео звонок',
                    Archive: 'Архив',

                    //Premium history
                    'Your Premium subscription is active till': 'Ваша подписка Premium активна до ',
                    'Purchase date': 'Дата покупки',
                    'Ticket number': 'Номер билета',
                    Type: 'Тип',
                    Purpose: 'Цель',
                    'Given by': 'Подарок от <1>{{userNick}}</1>',
                    'given by': 'подаренный',
                    'Gift for': 'Подарок для <1>{{userNick}}</1>',
                    deleted: 'удаленный',
                    'Give Premium': 'Подарить Premium',

                    //gifts
                    'Give a virtual gift to': 'Подарить вируальный подарок  ',
                    'You dont have any gifts': 'У вас нет подарков',
                    'doesnt have any gifts': 'не имеет подарков',
                    'Recipient only': 'Только получатель',
                    Nobody: 'Никто',
                    'Do you really want to delete this message': 'Вы действительно хотите удалить это сообщение?',
                    'an anonymous': 'анонимным пользователем',

                    // my favorites
                    'You have no favorite contributions': 'У вас нет избранных подборок',
                    'You have no favorite contributors': 'У вас нет избранных участников',
                    Delete: 'Удалить',

                    // video call
                    'Incoming video call': 'Входящий видео звонок',
                    Answer: 'Ответить',
                    Decline: 'Сбросить',
                    'Video call to': 'Видео звонок '
                }
            }
        },
        detection: {
            order: ['path']
        },
        fallbackLng: 'fr',
        react: {
            useSuspense: false
        }
    })

export const switchLang = (lang: string): void => {
    i18next
        .changeLanguage(lang)
        .then(() => {
            const oneYearFromNow = new Date()
            oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
            if (typeof document !== 'undefined') {
                document.cookie = `lang=${lang}; path=/ ;expires=${oneYearFromNow.toUTCString()}`
            }
        })
        .catch((e) => console.log(e))
}

export const getLangPrefix = (lang: string): string => {
    if (lang === 'en') return '/en'
    if (lang === 'ru') return '/ru'
    return ''
}

export const getPrefixFromPath = (path: string): string => {
    const pathBeginning = path.slice(0, 3)
    return pathBeginning.match(/\/(ru|en)/gm) ? pathBeginning : ''
}

export const removePrefixFromPath = (path: string): string => {
    const pathBeginning = path.slice(0, 3)
    return pathBeginning.match(/\/(ru|en)/gm) ? path.slice(3, path.length) : path
}

export const getLangFromPath = (path: string): string => {
    const lang = path.slice(1, 3)
    return lang.match(/(ru|en)/gm) ? lang : 'fr'
}

export default i18next
