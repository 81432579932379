import { createSlice, createAsyncThunk, PayloadAction, AnyAction } from '@reduxjs/toolkit'
import { FetchSearchList, FetchTags, FetchTagsList, TagsState } from './tagsTyping'
import { getSearchList, getSearchResults, getTagsList } from '../../api/tags'
import findIndex from 'lodash/findIndex'

const initialState: TagsState = {
    status: null,
    error: null,
    currentTag: '',
    currentSlug: '',
    disableLoading: false,
    materials: {
        list: [],
        total: 0
    },
    contributions: {
        list: [],
        total: 0
    },
    tags: {
        list: [],
        total_count: '0'
    },
    search: {
        list: [],
        total_count: '0'
    }
}

export const fetchSearchResults = createAsyncThunk('fetchSearchResults', async (params: FetchTags) => {
    return getSearchResults(params)
})

export const fetchTagsList = createAsyncThunk('fetchTagsList', async (params: FetchTagsList) => {
    return getTagsList(params)
})

export const fetchSearchList = createAsyncThunk('fetchSearchList', async (params: FetchSearchList) => {
    return getSearchList(params)
})

const tagsSlice = createSlice({
    name: 'tags',
    initialState,
    reducers: {
        clearSearchList(state) {
            state.materials.list = []
            state.contributions.list = []
        },
        clearTagAndSlug(state) {
            state.currentTag = ''
            state.currentSlug = ''
        },
        setCurrentTag(state, action) {
            state.currentTag = action.payload
        },
        setCurrentSlug(state, action) {
            state.currentSlug = action.payload
        },
        setDisableLoading(state, action) {
            state.disableLoading = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchResults.pending, (state) => {
                state.status = 'loading'
            })

            .addCase(fetchSearchResults.fulfilled, (state, action: PayloadAction<TagsState>) => {
                if (findIndex(state.materials.list, action.payload.materials.list[0]) === -1) {
                    state.materials.list = state.materials.list.concat(action.payload.materials.list)
                    state.materials.total = action.payload.materials.total
                }
                if (findIndex(state.contributions.list, action.payload.contributions.list[0]) === -1) {
                    state.contributions.list = state.contributions.list.concat(action.payload.contributions.list)
                    state.contributions.total = action.payload.contributions.total
                    state.status = action.payload.status
                }
                state.status = 'fulfiled'
            })

            .addCase(fetchSearchResults.rejected, (state) => {
                state.status = 'error'
                // state.error = action.payload.error
            })
            .addCase(fetchTagsList.fulfilled, (state, action: AnyAction) => {
                state.tags.list = action.payload.list
                state.tags.total_count = action.payload.total_count
            })
            .addCase(fetchSearchList.fulfilled, (state, action: AnyAction) => {
                state.search.list = action.payload.list
                state.search.total_count = action.payload.total_count
            })
    }
})
export const { clearSearchList, clearTagAndSlug, setCurrentTag, setCurrentSlug, setDisableLoading } = tagsSlice.actions
export default tagsSlice.reducer
