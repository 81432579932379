import { getWrapper } from './axios'

import { FetchProfile, FetchProfilePayload } from '../store/profile/profileTypings'
import { FetchProfiles, FetchProfilesPayload } from '../store/profiles/profilesTypings'

export const getProfiles = (params: FetchProfiles): Promise<FetchProfilesPayload> => {
    return getWrapper('/user/search', { params }) as Promise<FetchProfilesPayload>
}

export const getProfile = (params: FetchProfile): Promise<FetchProfilePayload> => {
    return getWrapper('/user/profile', { params }) as Promise<FetchProfilePayload>
}
