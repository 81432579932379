import i18next from '../i18next'

export function createLocalizedPath(page: string): string {
    if (i18next.language === 'ru') return '/ru' + page
    if (i18next.language === 'en') return '/en' + page
    return page
}

export const getPlural = (number: number, language: string) => {
    const single = 1
    const few = 2
    const other = 3

    if (language === 'ru') {
        const lastNumber = Number(String(number).split('').pop())
        if (lastNumber == 0) return other // 0/30/1000 результатов
        if (number >= 5 && number <= 20) return other // 5 результатов
        if (lastNumber == 1) return single // 1/21/1001 результат
        if (lastNumber >= 2 && lastNumber <= 4) return few // 2/43/1054 результата
        return other // 105/217/1018 результатов
    }
    if (language === 'en') {
        if (number == 1) return single // 1 result
        return other // 0/2/32 results
    }
    if (number <= 1) return single // 0/1 résultat
    return other // 2 résultats
}
