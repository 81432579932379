import * as React from 'react'
import { useState } from 'react'
import { FilterButtonContext } from './FilterButtonContext'

export const FilterButtonProvider: React.FunctionComponent = ({ children }) => {
    const [filterButton, setFilterButton] = useState(false)
    const [isNecessaryToShowFilters, setIsNecessaryToShowFilters] = useState(false)

    const defaultProps = {
        filterButton,
        setFilterButton,
        isNecessaryToShowFilters,
        setIsNecessaryToShowFilters
    }

    return <FilterButtonContext.Provider value={defaultProps}>{children}</FilterButtonContext.Provider>
}
